import React, { useMemo } from 'react';
import { AreaClosed } from '@visx/shape';
import { GridRows, GridColumns } from '@visx/grid';
import { scaleTime, scaleLinear } from '@visx/scale';
import { max, extent } from 'd3-array';
import { AxisLeft, AxisBottom } from '@visx/axis';
import { ScaleSVG } from '@visx/responsive';

// accessors
const getDate = (d) => new Date(d.date);
const getExpected = (d) => d.expected;
const getActual = (d) => d.actual;

export default function ReferralPDFChart({chartData, width, height}) {

  // data
  const data = chartData;
  
  // margins
  const margin = {top: 20, right: 20, bottom: 40, left: 50};
  
  // bounds
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  // colors
  const axisColor = '#000';
  const expectedAreaColor = '#00a2ff';
  const actualAreaColor = '#61d836';
  
  // scales
  const dateScale = useMemo(
    () =>
    scaleTime({
      range: [margin.left, innerWidth + margin.left],
      domain: extent(data, getDate),
    }),
    [innerWidth, margin.left, data],
  );
  const yScalePadding = 15;
  const productionScale = useMemo(
    () =>
    scaleLinear({
      range: [innerHeight + margin.top, margin.top],
      domain: [0, (max(data, getActual) + yScalePadding || 0)],
      nice: true,
    }),
    [margin.top, innerHeight, data],
  );
      
  return (
    <ScaleSVG width={width} height={height}>
      <svg width={width} height={height}>
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          fill="url(#area-background-gradient)"
          rx={14}
        />
        <GridRows
          left={margin.left}
          scale={productionScale}
          width={innerWidth}
          stroke={axisColor}
          strokeWidth={0.33}
          numTicks={5}
          pointerEvents="none"
          opacity={0.5}
        />
        <GridColumns
          top={margin.top}
          scale={dateScale}
          height={innerHeight}
          strokeDasharray="1,3"
          stroke={axisColor}
          pointerEvents="none"
          opacity={0.5}
          tickValues={[1, 5, 10, 15, 20, 25]}
        />
        <AreaClosed
          data={data}
          x={(d) => dateScale(getDate(d)) ?? 0}
          y={(d) => productionScale(getActual(d)) ?? 0}
          yScale={productionScale}
          fill={actualAreaColor}
        />
        <AreaClosed
          data={data}
          x={(d) => dateScale(getDate(d)) ?? 0}
          y={(d) => productionScale(getExpected(d)) ?? 0}
          yScale={productionScale}
          fill={expectedAreaColor}
        />
        <AxisLeft
          left={margin.left - 10}
          scale={productionScale}
          hideAxisLine
          hideTicks
          numTicks={5}
          tickFormat={production => production + 'kWh'}
          tickLabelProps={{
            fill: axisColor,
            fontSize: 9,
            textAnchor: 'middle',
          }}
        />
        <AxisBottom
          top={height - margin.bottom}
          tickFormat={date => date.toLocaleDateString('default', { month: 'short' })}
          scale={dateScale}
          stroke={axisColor}
          tickStroke={axisColor}
          hideTicks
          numTicks={12}
          tickLabelProps={{
            fill: axisColor,
            fontSize: 9,
            textAnchor: 'middle',
            dy: -5
          }}
        />
      </svg>
    </ScaleSVG>
  );
}
