import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import * as Constants from './functions/Constants'

const DarkOverlay = styled.div`
  background: black;
  height: 100vh;
  opacity: .3;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;

  @media only screen and (min-width: 600px){
    left: calc(100% / 3);
    opacity: 1;
    background: rgb(224, 224, 230);
    width: calc(100% / 3);
  }
`

const SurveyForm = styled.form`
  background: white;
  border: 1px solid var(--gray2);
  border-radius: var(--radius1);
  left: 50%;
  margin: 2rem auto;
  max-width: 40rem;
  position: fixed;
  top: 0;
  transform: translate(-50%);
  width: 90vw;
  z-index: 3;

  h1 {
    border-bottom: 1px solid var(--gray2);
    font-size: 1.2rem;
    font-weight: 700;
    padding: 1rem;
  }

  section {
    padding: 0 1rem 1.5rem 1rem;
  }

  p {
    margin-top: 1.5rem;
  }

  p:nth-child(3) {
    font-weight: 600;
  }

  label  {
    padding-left: .5rem;
  }

  footer {
    background: var(--gray1);
    border-bottom-left-radius: var(--radius1);
    border-bottom-right-radius: var(--radius1);
    border-top: 1px solid var(--gray2);
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
  }

  @media only screen and (min-width: 600px){
    left: calc(100% / 3);
    margin-left: 10px;
    top: 50%;
    transform: translate(0, -58%);
    width: calc(100% / 3 - 20px);
    padding: 20px;

    h1 {
      border-bottom: 0;
      font-size: 1.4rem;
      text-aling: center;
      font-weight: 700;
      padding: 0;
      width: 50%;
    margin: 0 auto;
    }

    section p:first-child span{
      color: black
    }

    section p:nth-child(3) {
      font-weight: 900;
      font-size: 20px;
    }

    section input{
      border-color: blue;
      background: blue
    }

    footer{
      background: white;
      border-top: 0;
    }
  }

`

const RequiredAsterisk = styled.span`
  color: red;
`

const DateSpan = styled.span`
  color: #4B7FFA;
  font-weight: 700;
`

const FooterButton = styled.button`
    border-radius: var(--radius1);
    padding: .5rem 1rem;
    ${props => props.primary ?
      `background-color: #4B7FFA;
      color: white;`
      :
      `margin-right: .5rem;`
    }
    :hover {
      ${props => props.cancel ? `text-decoration: underline` : null};
    }
    :disabled {
      background-color: var(--gray2);
    }
`

const InstallDateSurvey = (props) => {

  const [infoText, setInfoText] = useState('');
  const [dateText, setDateText] = useState('');
  const [promptText, setPromptText] = useState('');
  const [radioPrompt, setRadioPrompt] = useState('')
  const [radioOptions, setRadioOptions] = useState([null, null]);
  const [formButtonText, setFormButtonText] = useState('');
  const [formButtonBehavior, setFormButtonBehavior] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const radioTop = useRef();
  const radioBottom = useRef();

  const cancelClick = (e) => {
    e.preventDefault();
    props.setShowInstallDateSurvey(false);
  }

  const formatDate = (date) => {
    const jsDate = new Date(date);
    return (jsDate.toLocaleString('en-US', {timeStyle: 'short', timeZone: 'America/Chicago'}) +
            ', ' +
            jsDate.toLocaleString('en-US', {dateStyle: 'medium', timeZone: 'America/Chicago'})
    );
  }

  useEffect(() => {
    if (props.installData.installationScheduleDate === null && props.installData.installReturnDate === null && props.installData.customerHasCompletedInstallSurvey === false) {
      // User has no install or reschedule date and has not previously completed the survey
      setInfoText('This Survey will be available when your project is ready for scheduling confirmation.');
      setDateText('');
      setPromptText('');
      setFormButtonText('Finish');
      setFormButtonBehavior('close');
    }
    else if (props.installData.installationScheduleDate !== null && props.installData.installReturnDate === null) {
      if (props.installData.customerConfirmedInstallDate === false && props.installData.customerHasCompletedInstallSurvey === true) {
        // User has install date and has requested a reschedule date, but has no reschedule date yet.
        setInfoText('A new rescheduled date will be available within the next business day. Thanks for your patience. We will send you a note when your reschedule date is confirmed.');
        setDateText('');
        setPromptText('Please press "Finish" to exit.');
        setFormButtonText('Finish');
        setFormButtonBehavior('close');
      }
      else if (props.installData.customerConfirmedInstallDate === true && props.installData.customerHasCompletedInstallSurvey === true) {
        // User has install date and already confirmed it.
        setInfoText(`Your install date is confirmed for `);
        setDateText(formatDate(props.installData.installationScheduleDate));
        setPromptText('If you have any concerns or needs please reach out to your dedicated customer success representative.');
        setFormButtonText('Next');
        setFormButtonBehavior('nextInstall');
      }
      else {
        // Default: user has install date and has not confirmed or requested a reschedule yet.
        setInfoText(`Your install is currently scheduled for `);
        setDateText(formatDate(props.installData.installationScheduleDate));
        setPromptText('Please confirm below or request a new installation date.');
        setRadioPrompt('Confirm or Reschedule');
        setRadioOptions(['Confirm', 'Request Other Date']);
        setFormButtonText('Finish');
        setFormButtonBehavior('submitInstall');
        setButtonDisabled(true);
      }
    }
    else if (props.installData.installationScheduleDate !== null && props.installData.installReturnDate !== null) {
      if (props.installData.customerConfirmedReturnInstallDate === false && props.installData.customerHasCompletedInstallSurvey === true) {
        // User has return date and has requested a new return date, but has no return date yet.
        setInfoText('A new reschuled date will be available within the next business day. Thanks for your patience. We will send you a note when your reschedule date is confirmed.');
        setDateText('');
        setPromptText('Please press "Finish" to exit.');
        setFormButtonText('Finish');
        setFormButtonBehavior('close');
      }
      else if (props.installData.customerConfirmedReturnInstallDate === true && props.installData.customerHasCompletedInstallSurvey === true) {
        // User has return date and has already confirmed return date.
        setInfoText(`Your return install date is confirmed for `);
        setDateText(formatDate(props.installData.installReturnDate));
        setPromptText('If you have any concerns or needs please reach out to your dedicated customer success representative.');
        setFormButtonText('Finish');
        setFormButtonBehavior('close');
      }
      else {
        // User has return date, but has not confirmed or rescheduled yet.
        setInfoText(`Your install is currently scheduled for `);
        setDateText(formatDate(props.installData.installReturnDate));
        setPromptText('Please confirm below or request a new installation date. If a new installation date is requested please wait for a team member to reach out with your new date.');
        setRadioPrompt('Confirm or Reschedule');
        setRadioOptions(['Confirm', 'Request Other Date']);
        setFormButtonText('Finish');
        setFormButtonBehavior('submitRescheduled');
        setButtonDisabled(true);
      }
    }
    else
    {
      props.setShowInstallDateSurvey(false)
    }
  }, [])

  const submitData = (bodyData) => {
    const searchParams = new URLSearchParams({
      EverlightAppConnectPIN: props.accountIdv3,
      OpportunityId: props.installData.opportunityId
    });



    // Set local completion state before POSTing and confirming via GET sfData
    if (bodyData.confirmedInstallDate === true && bodyData.requestedNewInstallDate === false) {
      if(props.installData.installReturnDate)
      {
        props.setCompletedReturnInstallSurvey(true);
        props.setRejectedReturnInstallSurvey(false);
      }
      else
      {
        props.setCompletedInstallSurvey(true);
        props.setRejectedInstallSurvey(false);
      }
    }
    else if (bodyData.confirmedInstallDate === false && bodyData.requestedNewInstallDate === true) {
      if(!!props.installData.installReturnDate)
      {
        props.setCompletedReturnInstallSurvey(false);
        props.setRejectedReturnInstallSurvey(true);
      }
      else
      {
        props.setCompletedInstallSurvey(false);
        props.setRejectedInstallSurvey(true);
      }
    }



    fetch(`${Constants.JS_BACKEND_URL}/api/v1/survey/installdate?` + searchParams, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'AuthKey': '8f1163ec-163b-4a78-ba8e-97b61edbd686'
        },
        body: JSON.stringify(bodyData)
      })
      .then(res => res.json())
      .then(res => {
        setTimeout(() => {
          // TODOSFV4
          props.updateSFdata()
          .then(latestSFdata => {
              if(latestSFdata["error"]) {
                // SFv3 data not returned
              } else {
                  // SFv3 data returned successfully
                  props.setInstallSurveyReady(!!latestSFdata.customerHasCompletedInstallSurvey);

                  // Confirmed ORIGINAL date
                  if(latestSFdata.customerConfirmedInstallDate && latestSFdata.installReturnDate === null) {
                    props.setCompletedInstallSurvey(true);
                    props.setRejectedInstallSurvey(false);
                  }
                  // Reject ORIGINAL date
                  else if((latestSFdata.installationScheduleDate !== null && latestSFdata.customerConfirmedInstallDate === false && latestSFdata.customerHasCompletedInstallSurvey && latestSFdata.installReturnDate === null)) {
                    props.setRejectedInstallSurvey(true);
                    props.setCompletedInstallSurvey(false);
                  }

                  // Confirmed RETURN date
                  if((latestSFdata.customerConfirmedReturnInstallDate && latestSFdata.installReturnDate !== null)) {
                    props.setCompletedReturnInstallSurvey(true);
                    props.setRejectedReturnInstallSurvey(false);
                  }
                  // Reject RETURN date
                  else if((latestSFdata.installReturnDate !== null && latestSFdata.customerConfirmedReturnInstallDate === false && latestSFdata.customerHasCompletedInstallSurvey)) {
                    props.setRejectedReturnInstallSurvey(true);
                    props.setCompletedReturnInstallSurvey(false);
                  }
              }
          })
          .catch(error => {
            // TODO: error condition handling for secondary GET request
          });
        }, 10000)
      })
      .catch(error => {
        // TODO: error condition handling for POST request
      })
  }

  const onPrimaryButtonClick = (e) => {
    e.preventDefault();

    switch (formButtonBehavior) {
      case 'close' : {
        props.setShowInstallDateSurvey(false);
        break;
      }
      case 'nextInstall' : {
        // Confirm / Reschedule Screen - Previously confirmed install date.
        setInfoText(`Your install is currently scheduled for `);
        setDateText(formatDate(props.installData.installationScheduleDate));
        setPromptText('Please confirm below or request a new installation date.');
        setRadioPrompt('Confirm or Reschedule');
        setRadioOptions(['Confirm', 'Request Other Date']);
        setFormButtonText('Finish');
        setFormButtonBehavior('submitInstall');
        setButtonDisabled(true);
        break;
      }
      case 'submitInstall' : {
        if (radioTop.current.checked) {
          // Finished screen - 'Confirm' selected. POST install date confirmation to API.
          submitData({confirmedInstallDate: true, requestedNewInstallDate: false});
          setInfoText(`Thank you for confirming your installation date of `);
          setDateText(formatDate(props.installData.installationScheduleDate));
          setPromptText('Please press "Finish" to exit.');
          setRadioPrompt('');
          setRadioOptions([null, null]);
          setFormButtonText('Finish');
          setFormButtonBehavior('close');
        } 
        else if (radioBottom.current.checked) {
          // Finished screen - 'Reschedule' selected. POST reschedule request ot API.
          submitData({confirmedInstallDate: false, requestedNewInstallDate: true});
          setInfoText(`You have successfully requested a new installation date. You will receive an email notification once a new date has been determined and will be requested to confirm the new date on your Installation Tracker.`);
          setDateText('');
          setPromptText('Please press "Finish" to exit.');
          setRadioPrompt('');
          setRadioOptions([null, null]);
          setFormButtonText('Finish');
          setFormButtonBehavior('close');
        }
        break;
      }
      case 'nextRescheduled' : {
        // Confirm / Reschedule Screen - Previously confirmed rescheduled date.
        setInfoText(`Your install is currently scheduled for `);
        setDateText(formatDate(props.installData.installReturnDate));
        setPromptText('Please confirm below or request a new installation date.');
        setRadioPrompt('Confirm or Reschedule');
        setRadioOptions(['Confirm', 'Request Other Date']);
        setFormButtonText('Finish');
        setFormButtonBehavior('submitRescheduled');
        setButtonDisabled(true);
        break;
      }
      case 'submitRescheduled' : {
        if (radioTop.current.checked) {
          // Finished screen - 'Confirm' selected. POST reschedule date confirmation to API.
          submitData({confirmedInstallDate: true, requestedNewInstallDate: false})
          setInfoText(`Thank you for confirming your installation date of `);
          setDateText(formatDate(props.installData.installReturnDate));
          setPromptText('Please press "Finish" to exit.');
          setRadioPrompt('');
          setRadioOptions([null, null]);
          setFormButtonText('Finish');
          setFormButtonBehavior('close');
        } 
        else if (radioBottom.current.checked) {
          // Finished screen - 'Reschedule' selected. POST second reschedule request to API.
          submitData({confirmedInstallDate: false, requestedNewInstallDate: true})
          setInfoText(`You have successfully requested a new installation date. You will receive an email notification once a new date has been determined and will be requested to confirm the new date on your Installation Tracker.`);
          setDateText('');
          setPromptText('Please press "Finish" to exit.');
          setRadioPrompt('');
          setRadioOptions([null, null]);
          setFormButtonText('Finish');
          setFormButtonBehavior('close');
        } 
        break;
      }
      default: return;
    }
  }

  const onRadioInput = () => {
    setButtonDisabled(false);
  }

  return (
    <>
      <DarkOverlay onClick={cancelClick}></DarkOverlay>
      <SurveyForm>
        <h1>Installation Date Confirmation</h1>
        <section>
          <p>{infoText} {dateText ? <><DateSpan>{dateText}</DateSpan>.</> : null}</p>
          <p>{promptText}</p>
          {radioPrompt ?
          <>
            <p><RequiredAsterisk>* </RequiredAsterisk>{radioPrompt}</p>
            <div>
              <input ref={radioTop} id={radioOptions[0] + 'radio'} type='radio' name='installRadio' onInput={onRadioInput}/>
              <label htmlFor={radioOptions[0] + 'radio'}>{radioOptions[0]}</label>
            </div>
            <div>
              <input ref={radioBottom} id={radioOptions[1] + 'radio'} type='radio' name='installRadio' onInput={onRadioInput}/>
              <label htmlFor={radioOptions[1] + 'radio'}>{radioOptions[1]}</label>
            </div>
          </>
          : null }
        </section>
        <footer>
          <FooterButton cancel onClick={cancelClick}>Cancel</FooterButton>
          <FooterButton disabled={buttonDisabled} onClick={onPrimaryButtonClick} primary>{formButtonText}</FooterButton>
        </footer>
      </SurveyForm>
      <div className='account-page-blur-panel' style={{backgroundColor: 'rgba(0, 0, 0, 0.3)'}}></div>
    </>
  )
}

export default InstallDateSurvey