import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #555555;
    align-items: flex-end;
    margin-top: 30px;
`;

const fontSize = '14px';

const Heading = styled.h1`
    font-size: ${fontSize};
    text-align: center;
    font-family: 'Avenir';
    font-weight: bold;
    color: ${props => props.color};
    text-transform: uppercase;
`

const DataParagraph = styled.p`
    font-size: ${fontSize};
    font-family: 'Avenir';
    text-align: center;
    color: #555555;
    margin-bottom: 20px;
`

const Highlight = styled.span`
    font-size: ${fontSize};
    font-family: 'Avenir';
    color: #70cd41;
    font-weight: bold;
`

const ProductionData = (props) => {
    if (!props.referralData) {
        return null;
    }

    const actualProduction = props.referralData.actualProduction;
    const estimatedProduction = props.referralData.estimatedProduction;

    const twentyFiveYearSavings = props.referralData.oppData.Estimated_25yr_Savings__c;
    
    const estimatedProductionValue = removeFormatting(estimatedProduction.Everlight_Estimated_Annual_Production__c);
    const actualProductionValue = removeFormatting(actualProduction.Actual_Annual_Production__c);

    const percentageChange = Math.round(+actualProduction.Over_Deliver_Percentage__c);
    const estimatedTwentyFiveYearSavings = (twentyFiveYearSavings * (1 + percentageChange / 100)).toFixed(0);

    function removeFormatting(number) {
        return parseInt(number.toString().replace(/,/g, ''));
    }

    // add commas
    const formatNumber = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <Container>
            <Heading color="#489ff8">Original Production Estimate:</Heading>
            <DataParagraph>{formatNumber(estimatedProductionValue)} kWh</DataParagraph>
            <Heading color="#70cd41">Actual Production In Last 12 Months:</Heading>
            <DataParagraph>{formatNumber(actualProductionValue)} kWh <Highlight>(+{percentageChange}%)</Highlight></DataParagraph>
            <Heading color="#489ff8">Original 25yr Savings Estimate:</Heading>
            <DataParagraph>${formatNumber(parseInt(twentyFiveYearSavings).toFixed(0))}</DataParagraph>
            <Heading color="#70cd41">Projected 25yr Savings:</Heading>
            <DataParagraph>${formatNumber(estimatedTwentyFiveYearSavings)} <Highlight>(+{percentageChange}%)</Highlight></DataParagraph>
        </Container>
    );
};

export default ProductionData;