import React from 'react';
import styled from 'styled-components';
import EverlightLogo from "../images/EverlightLogoBlue.svg";

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 5px solid #489ff8;
    padding: 
`

const CustomerName = styled.h1`
    font-size: 34px;
    color: #489ff8;
    text-transform: uppercase;
    font-family: 'Avenir';
    font-weight: bold;
    line-height: .75;
    letter-spacing: .5px;
`

const PdfName = styled.h1`
    font-size: 34px;
    color: #489ff8;
    text-transform: uppercase;
    font-family: 'Avenir';
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: .5px;
`

const StyledLogo = styled.div`
    width: 70px;
    height: 70px;
    background: url(${EverlightLogo}) no-repeat top center;
    background-size: 127%;
    margin-bottom: 15px;
`

const ReferralHeader = (props) => {
    if (!props.referralData) {
        return null;
    }
    const name = props.referralData.oppData.Last_Name__c;

    return (
        <Header>
            <div>
                <CustomerName>{name}</CustomerName>
                <PdfName>Home Solar Review</PdfName>
            </div>
            <StyledLogo />
        </Header>
    )
}

export default ReferralHeader;