import { useRef, useState } from 'react';
import styled from 'styled-components';
import ErrorPopup from './ErrorPopup';

import * as Constants from './functions/Constants'

const Container = styled.div`
  display: flex;
  justify-content: center;
`

const Overlay = styled.div`
  background: black;
  height: 100vh;
  opacity: .3;
  position: fixed;
  width: 100%;
  z-index: 2;

  ${props => {
    if(!props.userIsOnMobilePhone)
        return `
            @media only screen and (min-width: 600px){
            position: fixed;
            background: rgb(228, 228, 234);
            opacity: 1;
            width: calc(100% / 3);
            left: calc(100% / 3);
            z-index: 0;
        }
        `
    }
  }
  
`

const Form = styled.form`
  background: white;
  border-radius: var(--radius1);
  margin: 2rem auto;
  max-width: 40rem;
  padding: 2rem;
  position: fixed;
  text-align: center;
  width: 90vw;
  z-index: 3;

  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  p {
    text-align: left;
    margin-bottom: 2rem;
  }

  ${props => {
    if(!props.userIsOnMobilePhone)
        return `
            @media only screen and (min-width: 600px){
                background: transparent;
                margin: auto;
                max-width: calc(100% / 3);
                position: absolute;
                width: calc(100% / 3 - 20px);
                margin-left: 10px;
                left: calc(100% / 3);
                top: 50%;
                bottom: auto;
                transform: translate(0%, -50%);
                font-size: 15px;
            }
            `
    }
  }
`

const InnerForm = styled.div`
  background-color: rgb(52, 120, 246);
  border-radius: 1rem;
  color: white;
  padding: 1rem;

  h2 {
    font-size: 1.75rem;
  }
`

const WhyButton = styled.button`
  color: rgb(52, 120, 246);
  font-size: 1rem;
  font-weight: 500;
  margin: .5rem;
  padding: .5rem;
`

const Input = styled.input`
  background-color: transparent;
  border-bottom: 1px solid rgb(209, 227, 253);
  font-size: 1rem;
  margin: .5rem;
  padding: .5rem;
  width: 90%;

  :focus {
    outline: none;
  }
  ::placeholder {
    color: rgb(209, 227, 253);
    font-weight: 400;
  }
`

const SubmitButton = styled.button`
  background-color: white;
  color: rgb(52, 120, 246);
  border-radius: .75rem;
  padding: .75rem 3rem;
  font-size: 1rem;
  font-weight: 500;
  margin: 1rem;
  width: 90%;
`

export default function WiFiSurvey(props) {
  // input refs
  const nameInput = useRef();
  const passwordInput = useRef();

  const [showExplanation, setShowExplanation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showNetworkError, setShowNetworkError] = useState(false);

  const overlayClickHandler = (e) => {
    e.preventDefault();
    if (window.innerWidth <= 600 || props.userIsOnMobilePhone) {
      props.setShowWiFiSurvey(false);
    }
  }

  const whyButtonClickHandler = (e) => {
    e.preventDefault();
    setShowExplanation(!showExplanation);
  }

  const submissionHandler = (e) => {
    e.preventDefault();

    const networkName = nameInput.current.value;
    const networkPassword = passwordInput.current.value;

    if (!networkName || !networkPassword) {
      // TODO: form validation and error messaging for these required fields
      return;
    }

    setIsSubmitting(true);
    fetch(Constants.JS_BACKEND_URL + '/customerNetworkInfo', {
      headers: {
        EverlightPin: props.accountId,
        customerNetworkName: networkName,
        customerNetworkPassword: networkPassword
      }
    })
      .then(res => res.json())
      .then(res => {
        // Request successful
        setIsSubmitting(false);
        props.setShowWiFiSurvey(false);
        props.setCompletedWiFi(true);

        // Account page UI state update
        if (props.setWiFiClass) {
          props.setWiFiClass('uploadSection');
        };

        // After delay, check if SF updated properly
        setTimeout(() => {
            props.updateSFdata()
            .then(latestSFData => {
              if (
                latestSFData[0].customerNetworkName !== networkName || 
                latestSFData[0].customerNetworkPassword !== networkPassword
              ) {
                // SF data hasn't updated properly; revert completed state to false
                props.setCompletedWiFi(false);
              }
            })
            .catch((err) => {
              // TODO: error handling for if SF data call fails
            });
        }, 3000);
      })
      .catch(err => {
        setIsSubmitting(false);
        setShowNetworkError(true);
      });
  }

  return (
    <Container>
      <Overlay onClick={overlayClickHandler} userIsOnMobilePhone={props.userIsOnMobilePhone} />
      <Form>
        <h1>Update Wi-Fi Credentials</h1>

        <InnerForm>
          <h2>Wi-Fi Network Info</h2>
          <Input
            ref={nameInput}
            type='text'
            id='wifi-name-input'
            name='customerNetworkName'
            placeholder='Enter Wi-Fi Network (2.4 GHz)'
          />
          <Input
            ref={passwordInput}
            type='text'
            id='wifi-password-input'
            name='customerNetworkPassword'
            placeholder='Enter Wi-Fi Password (2.4 GHz)'
          />
          <SubmitButton onClick={submissionHandler} > {isSubmitting ? 'Submitting...' : 'Submit'}</SubmitButton>
        </InnerForm>

        <WhyButton onClick={whyButtonClickHandler} >Why do you need my Wi-Fi credentials?</WhyButton>
        {showExplanation &&
          <p>
            Our energy monitoring devices record your data on a secure remote server
            over YOUR connected 2.4 GHz Wi-Fi network. Please provide your latest Wi-Fi
            credentials here any time you update your 2.4 GHz network to ensure you have
            the most updated and accurate energy data.
          </p>
        }
      </Form>
      {showNetworkError && 
        <ErrorPopup
          heading='Error Saving Network Info'
          body='Please check your connection and try again. If the issue persist contact customer support!'
          buttonCallback={ () => setShowNetworkError(false) } 
        />
      }
    </Container>
  )
};
