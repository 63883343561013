import styled from "styled-components";

const Table = styled.table`
  margin-top: 2rem;
  width: 100%;
  max-width: 500px;
  background-color: ${props => props.theme.panelBgColor};

  caption {
    padding: .5rem;
    font-weight: 400;
    font-size: 1.25rem;
    background-color: ${props => props.theme.headingBgColor};
    color: ${props => props.theme.headingTextColor};
  }
  th {
    text-align: left;
    font-weight: 400;
    padding: .5rem;
  }
  td {
    text-align: right;
    padding: .5rem;
  }
`

export default function HTOCostOfDoingNothingTable({chartData}) {

  return (
    <Table>
      <caption>THE COST OF DOING NOTHING</caption>
      <tbody>
        <tr>
          <th scope='row'>Estimated Current Avg. Monthly Bill</th>
          <td>${(chartData.monthlyBill).toFixed(2)}</td>
        </tr>
        <tr>
          <th scope='row'>Estimated Current Annual Utility Bill</th>
          <td>${Math.round(chartData.monthlyBill * 12).toLocaleString('en-US')}</td>
        </tr>
        <tr>
          <th scope='row'>Estimated Annual Utility Price Escalator</th>
          <td>4-8%</td>
        </tr>
        <tr>
          <th scope='row'>Estimated Projected 25-Year Cost</th>
          <td>${Math.round(chartData.twentyFiveYearUtilityCost).toLocaleString('en-US')}</td>
        </tr>
      </tbody>
    </Table>
  )
}
