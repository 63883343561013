const mockReferralDataFull = [
  {
    date: new Date('1-1-23'),
    expected: 27,
    actual: 52
  },
  {
    date: new Date('2-1-23'),
    expected: 36,
    actual: 79
  },
  {
    date: new Date('3-1-23'),
    expected: 63,
    actual: 143
  },
  {
    date: new Date('4-1-23'),
    expected: 123,
    actual: 171
  },
  {
    date: new Date('5-1-23'),
    expected: 144,
    actual: 213
  },
  {
    date: new Date('6-1-23'),
    expected: 170,
    actual: 245
  },
  {
    date: new Date('7-1-23'),
    expected: 175,
    actual: 240
  },
  {
    date: new Date('8-1-23'),
    expected: 160,
    actual: 220
  },
  {
    date: new Date('9-1-23'),
    expected: 130,
    actual: 191
  },
  {
    date: new Date('10-1-23'),
    expected: 100,
    actual: 150
  },
  {
    date: new Date('11-1-23'),
    expected: 70,
    actual: 100
  },
  {
    date: new Date('12-1-23'),
    expected: 50,
    actual: 80
  },
]

export default mockReferralDataFull;
