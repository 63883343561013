import { useRef, useState } from 'react';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { FreeMode } from 'swiper';
import 'swiper/css';
import "swiper/css/free-mode";

import { cssVar } from './functions/FunctionsInGeneral.js';
import './NotCustomerTab.css';
import EverlightStates from './images/EverlightStates.png';


const SwiperResizeHandler = (props) => {
    const swiper = useSwiper();
    window.visualViewport.addEventListener('resize', () => {
        props.resizer(swiper)
    });

    return (null)
}

const NotCustomerTab = () => {
    const contentSlide = useRef();
    const spacerDiv = useRef();
    const pullTab = useRef();
    const horizontalBar = useRef();
    const mapContainer = useRef();
    const mapImg = useRef();

    const SLIDER_TOP_OFFSET = 20;
    const MAP_IMG_RATIO = 1602 / 962;
    const MAP_IMG_MAX_WIDTH = 600;

    const [swiper, setSwiper] = useState();

    const resizeSwiper = (swiper) => {
        if (mapContainer.current) {
            // Set map container height
            const containerWidth = Math.min(window.visualViewport.width, MAP_IMG_MAX_WIDTH);
            let containerHeight = containerWidth / MAP_IMG_RATIO;
            containerHeight = Math.min(containerHeight, document.querySelector('.safe').clientHeight - pullTab.current.clientHeight - contentSlide.current.clientHeight + mapContainer.current.clientHeight - SLIDER_TOP_OFFSET);
            mapContainer.current.style.height = containerHeight + 'px';

            // Set spacer div height
            let spacerHeight = document.querySelector('.safe').clientHeight - pullTab.current.clientHeight - contentSlide.current.clientHeight - SLIDER_TOP_OFFSET;
            spacerHeight = Math.max(spacerHeight, 0);
            spacerDiv.current.style.height = spacerHeight + 'px';
            swiper.update();
        }
    }

    const scaleMap = (swiper) => {
        let maxProgress, minProgress;
        if (swiper.snapGrid.length === 4) {
            maxProgress = swiper.snapGrid[2] / swiper.snapGrid[3];
            minProgress = (contentSlide.current.clientHeight - mapContainer.current.clientHeight - SLIDER_TOP_OFFSET) / swiper.snapGrid[3];
        } else { // This covers cases where the spacer div has no height
            maxProgress = 1;
            minProgress = (contentSlide.current.clientHeight - mapContainer.current.clientHeight - SLIDER_TOP_OFFSET) / swiper.snapGrid[2];
        }
        let scaler = (swiper.progress - minProgress) / (maxProgress - minProgress);
        scaler = Math.min(scaler, 1);
        scaler = Math.max(scaler, 0);
        mapImg.current.style.transform = `scale(${scaler})`
    }

    const onSwiperTouchEnd = (swiper) => {
        // Prevent swiper from being pulled lower than start position
        if (swiper.activeIndex === 0) {
            swiper.slideTo(1, 300);
        }
        // Smooth out map scale transition on click and drag release. Transition duration should equal setTimeout duration.
        mapImg.current.style.transition = 'transform .3s';
        setTimeout(() => mapImg.current.style.transition = '', 300);
    }

    const onBarClick = (e) => {
        e.stopPropagation();
        if (swiper.progress === 1) {
            swiper.slideTo(1, 300);
        } else if (swiper.activeIndex === 2) {
            swiper.slideTo(3, 300);
        } else {
            swiper.slideTo(2, 300);
        }
    }

    const onPanelClick = () => {
        if (swiper.activeIndex === 1) {
            swiper.slideTo(3, 300);
        } else {
            swiper.slideTo(1, 300);
        }
    }

    const addTabHighlight = () => {
        horizontalBar.current.style.backgroundColor = cssVar('--pullTabHighlight')
    }

    const removeTabHighlight = () => {
        horizontalBar.current.style.backgroundColor = cssVar('--pullTabBar')
    }

    return (
        <Swiper
            direction={"vertical"}
            initialSlide={1}
            autoHeight={true}
            grabCursor={true}
            onTouchEnd={onSwiperTouchEnd}
            slidesPerView={"auto"}
            modules={[FreeMode]}
            onSwiper={swiper => setSwiper(swiper)}
            onAfterInit={resizeSwiper}
            onProgress={scaleMap}
            freeMode={{
                enabled: true,
                momentum: false,
                sticky: true
            }}
        >
            <SwiperResizeHandler resizer={resizeSwiper} />
            <SwiperSlide>
                <div ref={pullTab} onClick={onPanelClick} className='pull-tab'>
                    <div className='bar-container' onClick={onBarClick}>
                        <div
                            ref={horizontalBar}
                            onTouchStart={addTabHighlight}
                            onTouchEnd={removeTabHighlight}
                            className='horizontal-bar'
                        ></div>
                    </div>
                    <h1>NOT A CUSTOMER?</h1>
                </div>
            </SwiperSlide>
            <SwiperSlide ref={contentSlide} onClick={onPanelClick}>
                <p className='pull-tab-text'>Get a free quote for your solar system if you live where America's Best Solar Company operates</p>
                <a className='pull-tab-link' href="https://www.everlightsolar.com/contact-us/">GET FREE DESIGN</a>
                <div ref={mapContainer} className='map-container'>
                    <img
                        ref={mapImg}
                        className='pull-tab-img'
                        src={EverlightStates}
                        alt="Map of Everlight availability: Idaho, Minnesota, Nebraska, Nevada, Oregon, Utah, Wisconsin, Wyoming"
                    />
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div ref={spacerDiv} className='spacer-slide'></div>
            </SwiperSlide>
        </Swiper>
    );
}

export default NotCustomerTab;