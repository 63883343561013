import styled, { useTheme } from 'styled-components';
import SolarSavingsBarChart from '../HTOCharts/SolarSavingsBarChart';
import SolarSavingsLegend from '../HTOCharts/SolarSavingsLegend';
import HTOFinancingOptionsOverviewTable from '../HTOTables/HTOFinancingOptionsOverviewTable';
import { useRef } from 'react';
import useOnScreen from '../../functions/useOnScreen';

const SlideContainer = styled.section`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: ${props => props.theme.slideBottomMargin};
  text-align: center;
  margin: 8rem 0;

  h1 {
    height: 4rem;
    font-size: ${props => props.theme.slideHeadingSize};
    text-transform: uppercase;
    margin: 1rem 0 2rem 0;
  }
`

const SubsectionContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  flex: 1;
  width: 100%;
`

const Subsection = styled.div`
  flex: ${props => props.flexPortion};
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  h2 {
    background-color: ${props => props.theme.headingBgColor};
    color: ${props => props.theme.headingTextColor};
    font-size: 1.75rem;
    font-weight: 400;
    text-transform: uppercase;
    width: 100%;
    margin-bottom: .5rem;
    padding: 0.5rem;
  }
`

const ChartWrapper = styled.div`
  width: 100%;
`

const Card = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin: 1rem;
`

const LargeFigure = styled.p`
  font-size: 4rem;
`

const SmallFigure = styled.p`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`

const FinePrint = styled.p`
  font-size: .75rem;
  margin-bottom: 2rem;
`

export default function HTOSavingsOverviewSlide({chartData}) {
  const theme = useTheme();

  const chartOnScreenRef = useRef(null);
  const chartIsOnScreen = useOnScreen(chartOnScreenRef);

  return (
    <SlideContainer className={theme.slideClassName}>
      <h1>Solar Savings Overview</h1>
      <SubsectionContainer>
        <Subsection flexPortion={4}>
          <Card>
            <h2>Monthly Loan Payment</h2>
            <LargeFigure>${chartData.monthlyPaymentOption1}</LargeFigure>
            <SmallFigure>${chartData.monthlyPaymentOption1After18}</SmallFigure>
            <FinePrint>Monthly payment if incentive investment not paid toward loan during 16 month window</FinePrint>
          </Card>
          <Card>
            <ChartWrapper ref={chartOnScreenRef} chartIsOnScreen={chartIsOnScreen}>
              <SolarSavingsBarChart chartData={chartData} height={250} width={750} chartIsOnScreen={chartIsOnScreen}/>
            </ChartWrapper>
            <SmallFigure>25 Year Average Cost per kWh</SmallFigure>
          </Card>
        </Subsection>
        <Subsection flexPortion={5}>
          <Card>
            <HTOFinancingOptionsOverviewTable chartData={chartData} />
          </Card>
          <Card>
            <SolarSavingsLegend />
          </Card>
        </Subsection>
      </SubsectionContainer>
    </SlideContainer>
  )
}