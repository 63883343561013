import React from 'react'
import AlertBanner from '../AlertBanner';
import { useEffect } from 'react'
import '../App.css';
import HOASurveyForm from '../HOASurveyForm';
import customerSuccessRep from '../images/customerSuccessRep.png'
import EverlightEmail from "../images/icons/EverlightEmailIcon.svg"
import InstallDateSurvey from '../InstallDateSurvey';
import EverlightLogo from "../images/EverlightLogoBlack.png";
import TextMyECicon from "../images/icons/TextMyECGrayScale.png"
import iPhoneRightArrow from '../images/icons/RightChevronWhite.svg'
import { useWindowWidth } from '../functions/useWindowWidth';
const RepPage = (props) => {

  let phoneNumber = "tel:" + props.sfData.csrPhone

  let ECPhoneNumber = "sms:" + props.sfData.energyConsultantPhoneNumber


  const windowWidth = useWindowWidth()
  // To be Used at a later Date
  // function timestamp() {
  //   var response = document.getElementById("g-recaptcha-response");
  //   if (response == null || response.value.trim() == "") {
  //     try {
  //       var elems = JSON.parse(document.getElementsByName("captcha_settings")[0].value);
  //       elems["ts"] = JSON.stringify(new Date().getTime());
  //       document.getElementsByName("captcha_settings")[0].value = JSON.stringify(elems);
  //     } catch {

  //     }
  //   }
  // }
  // setInterval(timestamp, 500);


  // useEffect(() => {
  //   const script = document.createElement('script');

  //   script.src = "https://www.google.com/recaptcha/api.js";
  //   script.async = true;

  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   }
  // }, []);


  return (
    <div className='repPageWrap'>

      {/* {props.showInstallDateSurvey ? <InstallDateSurvey installData={props.installData} setShowInstallDateSurvey={props.setShowInstallDateSurvey} accountIdv3={props.accountIdv3} /> : null}
      {props.showHOASurvey ? <HOASurveyForm setShowHOASurvey={props.setShowHOASurvey} accountId={props.accountId} accountIdv3={props.accountIdv3} sfData={props.sfData} /> : null}
      {props.alertData.text ? <AlertBanner alertData={props.alertData} setShowHOASurvey={props.setShowHOASurvey} setShowInstallDateSurvey={props.setShowInstallDateSurvey} /> : null} */}


      <div className='box-center center rep-page-container'>

        <div className="margin60px"></div>
        <h3 className='grayText bold dedicatedRepTitle center-text'>Your Dedicated <br /> Customer Success Rep</h3>
        <div className='margin30px'></div>


        <img src={props.sfData.customerSuccessRepImageURL !== "/file-asset/FINALSupportREpOVal?v=1" ? props.sfData.customerSuccessRepImageURL : customerSuccessRep}  className={props.sfData.customerSuccessRepImageURL !== "/file-asset/FINALSupportREpOVal?v=1" ?  'repPagePic' : 'repPageBigPic'} />
        <div className={props.sfData.customerSuccessRepImageURL !== "/file-asset/FINALSupportREpOVal?v=1" ?  null : 'borderRep'}></div>
        <div className='margin5px'></div>
        <h3 className='repName'>{props.sfData.csrFirstName ? props.sfData.csrFirstName : 'CSR First Name'}</h3>
        <div className='margin60px'></div>


        <div className='repActions'>
          <div style={{ background: 'white', borderRadius: '10px' }}>
            <button className='repCallSection'>

              <div className='repCallNow'>
                <img src={props.sfData.customerSuccessRepImageURL !== "/file-asset/FINALSupportREpOVal?v=1" ? props.sfData.customerSuccessRepImageURL : customerSuccessRep} className='customerRepPic' />
                <div className='csrPhone'>
                <a className='' href={phoneNumber}>Call Now</a>
                {windowWidth < 600 ? <span>{props.sfData.csrPhone}</span>: null}
                </div>
              </div>

              <img src={iPhoneRightArrow} />

            </button>

            <div className='divider2'></div>

            <button className='repActions2'>
              <div className='repEmailSection'>
                <div className='repEmailNow'>
                  <div className='gradient4'>
                    <img src={EverlightEmail} className='emailIcon' />
                  </div>
                  <div className='csrPhone emailMobile'>
                  <a
                    href='mailto:Everlight%20Solar%20Customer%20Success<everlightcustomersuccess+everlightappreppage@1tloqmjfeq1kivf1frknfx3sxbi0xkhhecj5xyppv1vear10fw.1u-11vnmuaq.na140.case.salesforce.com>?subject=General%20Reach-out%20from%20the%20Everlight%20App&body=(Insert%20Inquiry%20Here)'>Email Now</a>
                    {windowWidth < 600 ? <span>customersuccess@everlightsolar.com</span>: null}
                    </div>
                </div>
      
                <img src={iPhoneRightArrow} /></div>
            </button>
          </div>
        </div>
        <div className='margin30px'></div>
        {props.sfData.energyConsultantFirstName !== null || props.sfData.energyConsultantPhoneNumber !== null ? 
        <a href={ECPhoneNumber} className='textMyEC'>
          <div id="TextMyEC">
            <div>
              <img src={TextMyECicon} />
              <div>Text {props.sfData.energyConsultantFirstName}, my Energy Consultant</div>
            </div>
            <img src={iPhoneRightArrow} />
          </div>
        </a>
        :
        null
        }
        <div className='margin100px'></div>


      </div>


    </div>
  )
}

export default RepPage