import styled from 'styled-components';
import { LegendItem, LegendLabel, LegendOrdinal } from "@visx/legend";
import { scaleOrdinal } from "@visx/scale";

const LegendWrapper = styled.div`
  /* background-color: ${props => props.theme.panelBgColor};
  padding: 1rem;
  border-radius: 14px;
  margin-top: 2rem; */
  position: absolute;
  top: 6%;
  left: 3%;
`

export default function ConsumptionProductionLegend() {
  const colorScale = scaleOrdinal({
    domain: ['Solar Production', 'Power Consumption'],
    range: ['url(#production-gradient)', 'url(#consumption-gradient)'],
  })
  const legendGlyphSize = 15;

  return (
    <LegendWrapper>
      <LegendOrdinal scale={colorScale} >
        {(labels) => (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {labels.map((label, i) => (
              <LegendItem
                key={`legend-quantile-${i}`}
                margin="0 10px"
              >
                <svg width={legendGlyphSize} height={legendGlyphSize}>
                  <circle
                    fill={label.value}
                    cx={legendGlyphSize / 2}
                    cy={legendGlyphSize / 2} 
                    r={legendGlyphSize / 2 - 1}
                    stroke={label.value}
                  />
                </svg>
                <LegendLabel align="left" margin="0 0 0 6px">
                  {label.text}
                </LegendLabel>
              </LegendItem>
            ))}
          </div>
        )}
      </LegendOrdinal>
    </LegendWrapper>
  )
}
