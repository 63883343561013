import styled, { useTheme } from 'styled-components';
import EnergyCostsBarChart from '../HTOCharts/EnergyCostsBarChart';
import EnergyCostsLegend from '../HTOCharts/EnergyCostsLegend';
import HTOForestInfographic from '../HTOForestInfographic';
import HTOCostOfDoingNothingTable from '../HTOTables/HTOCostOfDoingNothingTable';
import { useRef } from 'react';
import useOnScreen from '../../functions/useOnScreen';

const SlideContainer = styled.section`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: ${props => props.theme.slideBottomMargin};
  width: 100%;
  margin: 8rem 0;

  h1 {
    font-size: ${props => props.theme.slideHeadingSize};
    padding: 0 .5rem 0 .5rem;
    text-align: center;
  }
`

const SubsectionContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  width: 100%;
  flex: 1;
`

const Subsection = styled.div`
  flex: 1;
  min-width: 375px;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 1rem;
`

const ChartWrapper = styled.div`
  width: 100%;
  background: rgb(246, 245, 247);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export default function HTOEnergyCostsSlide({chartData}) {
  const theme = useTheme();

  const chartOnScreenRef = useRef(null);
  const chartIsOnScreen = useOnScreen(chartOnScreenRef);

  return (
    <SlideContainer className={theme.slideClassName}>
      <h1>ENERGY COSTS</h1>
      <SubsectionContainer>
        <Subsection>
          <ChartWrapper ref={chartOnScreenRef}>
            <EnergyCostsBarChart chartData={chartData} height={500} width={750} chartIsOnScreen={chartIsOnScreen} />
            <EnergyCostsLegend />
          </ChartWrapper>
          <HTOCostOfDoingNothingTable chartData={chartData} />
        </Subsection>
        <Subsection>
          <HTOForestInfographic />
        </Subsection>
      </SubsectionContainer>
    </SlideContainer>
  )
}
