import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import HousePin from './images/icons/HousePin.svg';
import HouseIcon from './images/icons/HouseWhite.svg';

const MapContainer = styled.div`
  height: 100vh;
  width: 100%;
  position: absolute;
  z-index: -1;
  overflow-y: hidden;

  .map-annotation {
    background: 50% 30%/75% url(${HouseIcon}), center url(${HousePin});
    background-repeat: no-repeat;
    height: 3rem;
    width: 2rem;
  }
`


export default function SystemPageMapKit({verifiedProjectLatitude, verifiedProjectLongitude, projectAddress, projectState}) {
  let [latitude, setLatitude] = useState(null);
  let [longitude, setLongitude] = useState(null);

  const annotationFactory = () => {
    const pinDiv = document.createElement('div');
    pinDiv.className = 'map-annotation';
    return pinDiv;
  }

  const renderMap = (latitude, longitude) => {

    const latitudeOffset = 0.002; // Shifts property lower on map container for UI purposes
    const region = new window.mapkit.CoordinateRegion(
      new window.mapkit.Coordinate(latitude + latitudeOffset, longitude),
      new window.mapkit.CoordinateSpan(0.007)
    );
    
    console.log('CREATING NEW MAP')
    const map = new window.mapkit.Map("system-page-map-container", {
            mapType: window.mapkit.Map.MapTypes.MutedStandard // Set the color scheme to dark
          });
    map.region = region;
    map.isScrollEnabled = false;
    map.isZoomEnabled = false;
    map.pointOfInterestFilter = window.mapkit.PointOfInterestFilter.including([]);
  
    const propertyCoordinate = new window.mapkit.Coordinate(latitude, longitude);
    const propertyPin = new window.mapkit.Annotation(propertyCoordinate, annotationFactory);

    map.addAnnotation(propertyPin);
  }

  useEffect(() => {
    if (latitude) {
        if(document.getElementById('system-page-map-container').innerHTML != '')
        {
            console.log("Maps already exist - clearing old ones")
            document.getElementById('system-page-map-container').innerHTML = ''
        }
            renderMap(latitude, longitude);
    }
  }, [latitude])

  useEffect(() => {
    // If this opp has verified longitude and latitude values
    if(verifiedProjectLatitude && verifiedProjectLongitude)
    {
        console.log("1 - VERIFIED VALUES FOUND AND SET")
        setLatitude(parseFloat(verifiedProjectLatitude))
        setLongitude(parseFloat(verifiedProjectLongitude))
    }
    else
    {
        console.log("ATTEMPTING TO LOOK UP COORDINATES BY ADDRESS")
        // Attempt to look up longitude and latitude by address
        const geocoder = new window.mapkit.Geocoder({
            language: 'en-US'
          });
      
          const formattedAddress = projectAddress + ', ' + projectState + ', US';
          geocoder.lookup(formattedAddress, (error, data) => {
            if (error || !data.results[0]) {
                console.log("2 - Lookup failed, using default warehouse coordinates")
                // Coordinate look-up failed; use default coordinates
                setLatitude(42.97555);
                setLongitude(-89.50475);
            } else {
                console.log("3 - Lookup succeeded")
                // Coordinate look-up success; use returned coordinates
                setLatitude(data.results[0].coordinate.latitude);
                setLongitude(data.results[0].coordinate.longitude);
            }
          })
    }
  }, [verifiedProjectLatitude, verifiedProjectLongitude])

  return (
    <MapContainer id='system-page-map-container' />
  )
}
