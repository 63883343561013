import { useEffect, useState } from "react"
import styled, { ThemeProvider, css } from 'styled-components';
import { useParams } from "react-router-dom"
import transformHTOChartData from "../functions/transformHTOChartData";
import HTOEnergyCostsSlide from "../HTOComponents/HTOSlides/HTOEnergyCostsSlide";
import HTOInvestmentIntoSolarSlide from "../HTOComponents/HTOSlides/HTOInvestmentIntoSolarSlide";
import HTOSystemDesignSlide from "../HTOComponents/HTOSlides/HTOSystemDesignSlide";
import HTOSystemOverviewSlide from "../HTOComponents/HTOSlides/HTOSystemOverviewSlide";
import HTOSavingsOverviewSlide from "../HTOComponents/HTOSlides/HTOSavingsOverviewSlide";
import HTOSavingsReportSlide from "../HTOComponents/HTOSlides/HTOSavingsReportSlide";
import { HTOMainTheme } from "../HTOComponents/HTOThemes/HTOMainTheme";
import { AnimationContext } from "../HTOComponents/HTOContext/AnimationContext";
import HTOTitleSlide from "../HTOComponents/HTOSlides/HTOTitleSlide";

const PageContainer = styled.div`
  background-color: ${HTOMainTheme.pageBgColor};
  color: ${HTOMainTheme.pageTextColor};
  height: 100vh;
  overflow-y: scroll;

  ${props => props.scrollSnapEnabled && css`
    scroll-snap-type: y mandatory;
    > section {
      scroll-snap-align: start;
      scroll-snap-stop: always;
    }`
  }
  
  @font-face {
    font-family: Avenir;
    font-weight: bold;
    src: url("./fonts/AvenirNextLTPro-Bold.otf") format("opentype");
  }
`

const PageBreak = styled.div`
  background-color: rgb(38, 38, 38);
  height: 1rem;
`

const HTOProposalPage = ({showOverlay}) => {
  const { EverlightPIN, keyword } = useParams();
  const [chartData, setChartData] = useState();

  const animationEnabled = keyword !== 'generate';

  const PROPOSAL_DATA_API_URL = 'https://damp-ridge-21682.herokuapp.com/proposalData';

  // Get proposal data
  useEffect(() => {
    fetch(PROPOSAL_DATA_API_URL, {
      headers: {
        'Content-Type': 'text/plain',
        'EverlightPIN': EverlightPIN
      },
    })
      .then(response => response.json())
      .then(rawChartData => {
        const transformedChartData = transformHTOChartData(rawChartData);
        setChartData(transformedChartData);
      })
      .catch(error => {
        // TODO: Error handling for proposal data fetch
      });
  }, []);

  return (
    <PageContainer scrollSnapEnabled={keyword !== 'generate'}>
      {chartData && !showOverlay &&
        <AnimationContext.Provider value={animationEnabled}>
          <ThemeProvider theme={HTOMainTheme}>
            <HTOTitleSlide chartData={chartData} isPDFVersion={keyword === 'generate'} />
            {keyword !== 'generate' && <PageBreak />}
            <HTOEnergyCostsSlide chartData={chartData} />
            {keyword !== 'generate' && <PageBreak />}
            <HTOInvestmentIntoSolarSlide chartData={chartData} />
            {keyword !== 'generate' && <PageBreak />}
            <HTOSystemDesignSlide chartData={chartData} />
            {keyword !== 'generate' && <PageBreak />}
            <HTOSystemOverviewSlide chartData={chartData} />
            {keyword !== 'generate' && <PageBreak />}
            <HTOSavingsOverviewSlide chartData={chartData} />
            {keyword !== 'generate' && <PageBreak />}
            <HTOSavingsReportSlide chartData={chartData} option={1} />
            {keyword !== 'generate' && <PageBreak />}
            <HTOSavingsReportSlide chartData={chartData} option={2} />
          </ThemeProvider>
        </AnimationContext.Provider>
      }
    </PageContainer>
  );
}

export default HTOProposalPage
