import styled from "styled-components";

const Table = styled.table`
  table-layout: fixed;
  text-align: center;
  width: 100%;
  flex: 1;


  caption {
    background-color: ${props => props.theme.headingBgColor};
    color: ${props => props.theme.headingTextColor};
    font-size: 1.75rem;
    font-weight: 400;
    text-transform: uppercase;
    padding: 0.5rem;
  }
  tbody tr {
    height: 2.5rem;
  }
  thead tr {
    height: 2.5rem;
  }
  thead th:nth-child(1) {
    width: 14rem;
  }
`

const HeaderCell = styled.th`
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    background-color: ${props => {
      switch(props.background) {
        case 'light': return props.theme.headingBgLightColor;
        case 'dark': return props.theme.headingBgColor;
        default: {
          return 'inherit';
        }
      }}
    };
    color: ${props => props.background ? props.theme.headingTextColor : props.theme.tableMainTextColor};
`

const DataCell = styled.td`
    background-color: ${props => {
    switch(props.background) {
      case 'mainLight': return props.theme.tableBgMainLightColor;
      case 'mainDark': return props.theme.tableBgMainDarkColor;
      case 'altLight': return props.theme.tableBgAltLightColor;
      case 'altDark': return props.theme.tableBgAltDarkColor;
      case 'tint': return props.theme.panelBgColor;
      default: return 'inherit';
    }}
  };
  color: ${props => props.theme.tableMainTextColor};
`

export default function HTOFinancingOptionsOverviewTable({chartData}) {

  const formattedData = {
    totalSystemCost: `$${chartData.totalSystemCost.toLocaleString('en-US')}`,
    taxCredit: `$${Math.round(chartData.systemTaxCredit).toLocaleString('en-US')}`,
    netSystemCost: `$${Math.round(chartData.totalSystemCost - chartData.systemTaxCredit).toLocaleString('en-US')}`,
    twentyFiveYearSavingsOption1: `$${Math.round(chartData.twentyFiveYearSavingsOption1).toLocaleString('en-US')}`,
    twentyFiveYearSavingsOption2: `$${Math.round(chartData.twentyFiveYearSavingsOption2).toLocaleString('en-US')}`,
    termLengthOption1: `${chartData.termLengthOption1} Years`,
    termLengthOption2: `${chartData.termLengthOption2} Years`,
    monthlyPaymentOption1: `$${chartData.monthlyPaymentOption1.toLocaleString('en-US')}`,
    monthlyPaymentOption2: `$${chartData.monthlyPaymentOption2.toLocaleString('en-US')}`
  }

  return (
    <Table>
      <caption>Your Financing Options</caption>
      <thead>
        <tr>
          <HeaderCell background='light'></HeaderCell>
          <HeaderCell scope='column'>Option #1</HeaderCell>
          <HeaderCell scope='column'>Option #2</HeaderCell>
        </tr>
      </thead>
      <tbody>
        <tr>
          <HeaderCell scope='row' background='dark'>Total System Cost</HeaderCell>
          <DataCell background='mainLight'>{formattedData.totalSystemCost}</DataCell>
          <DataCell background='mainLight'>{formattedData.totalSystemCost}</DataCell>
        </tr>
        <tr>
          <HeaderCell scope='row' background='light'>Federal Tax Credit (30%)</HeaderCell>
          <DataCell>{formattedData.taxCredit}</DataCell>
          <DataCell>{formattedData.taxCredit}</DataCell>
        </tr>
        <tr>
          <HeaderCell scope='row' background='dark'>Net System Cost</HeaderCell>
          <DataCell background='mainDark'>{formattedData.netSystemCost}</DataCell>
          <DataCell background='mainDark'>{formattedData.netSystemCost}</DataCell>
        </tr>
        <tr>
          <HeaderCell scope='row' background='light'>25 Year Savings</HeaderCell>
          <DataCell background='altLight'>{formattedData.twentyFiveYearSavingsOption1}</DataCell>
          <DataCell background='altLight'>{formattedData.twentyFiveYearSavingsOption2}</DataCell>
        </tr>
        <tr>
          <HeaderCell scope='row' background='dark'>Term Length</HeaderCell>
          <DataCell background='tint'>{formattedData.termLengthOption1}</DataCell>
          <DataCell background='tint'>{formattedData.termLengthOption2}</DataCell>
        </tr>
        <tr>
          <HeaderCell scope='row' background='light'>Monthly Payment</HeaderCell>
          <DataCell background='altDark'>{formattedData.monthlyPaymentOption1}</DataCell>
          <DataCell background='altDark'>{formattedData.monthlyPaymentOption2}</DataCell>
        </tr>
      </tbody>
    </Table>
  )
}
