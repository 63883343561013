import React from 'react';
import styled from 'styled-components';
import DollarIcon from './images/icons/DollarCircleBlack.svg';
import RocketIcon from './images/icons/RocketBlack.svg';
import RightChevronIcon from './images/icons/RightChevronWhite.svg';
import CSRDefaultImage from './images/customerSuccessRep.png';

const Container = styled.ul`
  align-items: flex-start;
  background-color: white;
  border-radius: 1.5rem;
  border: 4px solid black;
  display: flex;
  flex-flow: column nowrap;
  margin: 0 auto;
  width: fit-content;

  li {
    width: 100%;
  }
  a {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    padding: 0;
    width: 100%;
  }
  img {
    margin: .25rem 1rem;
    width: 2rem;
  }
  span {
    flex: 1;
    margin-right: 80px;
    font-size: 1.0rem;
    padding: .6rem 0;
    color: black
  }
`

const ButtonIcon = styled.img`
    height: 2rem;
`

const Chevron = styled.img`
  height: 1.25rem;
`

const LineSeparator = styled.div`
  background-color: var(--gray1);
  height: 2px;
  margin-left: 4rem;
  width: calc(100% - 4rem);
`

const ThreeButtons = (props) => {
    return (
        <Container>
            <li>
                <a href="https://www.everlightsolar.com/refer-a-friend/" target="_blank" rel='noreferrer'>
                    <ButtonIcon src={DollarIcon} alt='dollar sign' />
                    <span>Refer a Friend</span>
                    <Chevron src={RightChevronIcon} alt='right arrow' />
                </a>
            </li>
            <LineSeparator />
            <li>
                <a href="tel: (608)618-1515" target="_blank" rel='noreferrer'>
                    <ButtonIcon src={RocketIcon} alt='rocket ship' />
                    <span>Ask about an Upgrade</span>
                    <Chevron src={RightChevronIcon} alt='right arrow' />
                </a>
            </li>
            <LineSeparator />
            <li>
                <a href="https://everlightsolar.com/customer-support/" target="_blank" rel='noreferrer'>
                    <ButtonIcon src={props.CSRImageURL !== "/file-asset/FINALSupportREpOVal?v=1" ? props.CSRImageURL : CSRDefaultImage} alt='customer service representative' />
                    <span>Get in Touch</span>
                    <Chevron src={RightChevronIcon} alt='right arrow' />
                </a>
            </li>
        </Container>
    );
}

export default ThreeButtons;