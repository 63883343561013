import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useWindowWidth } from './functions/useWindowWidth';

ChartJS.register(
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ArcElement
);




const CircleChart = (props) =>
{
  const windowWidth = useWindowWidth()
  const cutOut = windowWidth > 600 && !props.userIsOnMobilePhone ? '70%': '50%'
  const cOptions = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
      title: {
          display: false,
          text: 'Cool Chart',
          color:'red',
          font: {
              size:34
          },
          padding:{
              top:30,
              bottom:30
          },
          responsive:true,
          animation:{
              animateScale: true,
                         }
      },
      
  },
  cutout: cutOut,
}


  let cData = {
    labels: ['A','B'],
    datasets: [
        {
            label: 'Cool Stuff',
            data: 
                props.ratio >= 1.0
                ?
                [100, 0]
                :
                [100 * props.ratio, 100 * (1.0 - props.ratio)],
            borderColor: ['rgba(255,206,86,0.2)'],
            backgroundColor: ['#91d655',
            '#4b7ffa', ],
            pointBackgroundColor: 'rgba(255,206,86,0.2)',
        }
  
    ]
  }
  return (
    <div className="chart center">
      <Doughnut data={cData} options={cOptions} />
    </div>
  );
}

export default CircleChart;
