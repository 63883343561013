const generateEnergyCostsData = (initialBill, utilityPriceEscalator) => {
  const yearTicks = [1, 5, 10, 15, 20, 25];
  return yearTicks.map(i => {
    // Must call Math.round on monthlyBill and yearlyBill seperately to avoid compounding rounding errors
    const monthlyBill = initialBill * ((1 + utilityPriceEscalator) ** (i - 1));
    return {
      year: i,
      monthlyBill: Math.round(monthlyBill),
      yearlyBill: Math.round(monthlyBill * 12)
    }
  })
}

const generateInvestmentIntoSolarData = (initialUtilityCost, utilityPriceEscalator) => {
  const investmentData = [];
  for (let i = 1; i < 26; i++) {
    const noiseOffset = !(i % 2) ? (i / 10) : !(i % 5) ? (i / 5) : 0;
    investmentData.push({
      year: i,
      utilityCost: Math.round(initialUtilityCost * ((1 + utilityPriceEscalator) ** (i - 1)) + noiseOffset)
    })
  }
  return investmentData;
}

const generateConsumptionProductionData = (yearlyPowerConsumption, yearlySolarProduction) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const consumptionCurve = [15/235,	17/235,	12/235,	13/235,	18/235,	27/235,	38/235,	35/235,	19/235,	13/235,	12/235,	16/235];
  const productionCurve = [11/267, 14/267,	19/267,	24/267,	31/267,	35/267,	35/267,	31/267,	24/267,	19/267,	14/267,	11/267];
  return months.map((month, i) => {
    return {
      month: month,
      consumption: yearlyPowerConsumption * consumptionCurve[i],
      production: yearlySolarProduction * productionCurve[i]
    }
  })
}

export default function transformHTOChartData(rawChartData) {
  // Constants
  const solarCostConstant = 40;
  const utilityPriceEscalator = 0.055;
  const utilityCostPerKwh = 0.18;
  const solar1CostPerKwh = 0.09;
  const solar2CostPerKwh = 0.07;
  const constantYearlyPowerConsumption = 7;
  const constantYearlySolarProduction = 8;

  // Image Data Parsing
  const EPFRData = rawChartData.epfrData;

  // Opportunity Data Parsing
  const oppData = rawChartData.opportunityData;

  // Energy Consultant Data Parsing
  const ecData = rawChartData.ecData;

  return {
    // SF Field Formatting
    monthlyBill: +oppData.Pre_Solar_Monthly_Payment__c,
    twentyFiveYearUtilityCost: +oppData.Opportunity_25_Yr_Estimated_Cost__c,
    solarPowerPercent: Math.min(+oppData.Estimated_Offset__c, +oppData.Solar_System_Offset_Maximum__c),
    utilityPowerPercent: Math.max(+oppData.Remaining_Offset__c, +oppData.Solar_System_Offset_Minimum__c),
    yearlySolarProduction: +oppData.Estimated_Production_1st_Year__c,
    yearlyPowerConsumption: +oppData.Opportunity_Lookup__r.Estimated_Current_Usage_kWh__c,
    customSolarDesignImage: EPFRData.Current_Design_3D_Render_URL__c,
    systemSize: oppData.Opportunity_Lookup__r.System_Size__c,
    panelQuantity: +oppData.Quantity_Of_Panels__c,
    panelManufacturer: oppData.Panel_Manufacturer__c ?? 'Missing Panel Manufacturer',
    panelName: oppData.Panel_Name__c ?? 'Missing Panel Name',
    inverterQuantity: +oppData.Quantity_Of_Inverters__c,
    inverterManufacturer: oppData.Inverter_Manufacturer__c ?? 'Missing Inverter Manufacturer',
    inverterName: oppData.Inverter_Name__c ?? 'Missing Inverter Name',
    totalSystemCost: +oppData.Total_Sale_Price__c,
    systemTaxCredit: +oppData.Estimated_Incentives__c,
    twentyFiveYearSolarCostOption1: +oppData.OP1_25_Yr_Solar_Cost__c,
    twentyFiveYearSolarCostOption2: +oppData.OP2_25_Yr_Solar_Cost__c,
    twentyFiveYearSavingsOption1: +oppData.OP1_25_Yr_Savings__c,
    twentyFiveYearSavingsOption2: +oppData.OP2_25_Yr_Savings__c,
    monthlyPaymentOption1: +oppData.OP1_Monthly_Payment_Before_18Mo__c,
    monthlyPaymentOption2: +oppData.OP2_Monthly_Payment_Before_18Mo__c,
    monthlyPaymentOption1After18: +oppData.OP1_Monthly_Payment_After_18Mo__c,
    twentyFiveYearRemainingUtilityCost: +oppData.Opportunity_25_Yr_Remaining_Utility_Cost__c,
    termLengthOption1: +oppData.OP1_Term__c,
    termLengthOption2: +oppData.OP2_Term__c,
    loanCompanyNameOption1: oppData.OP1_Loan_Company_Name__c,
    loanCompanyNameOption2: oppData.OP2_Loan_Company_Name__c,
    loanAPROption1: oppData.OP1_APR__c,
    loanAPROption2: oppData.OP2_APR__c,
    customerName: oppData.Opportunity_Lookup__r.First_Name__c + ' ' + oppData.Opportunity_Lookup__r.Last_Name__c,
    coborrowerName: oppData.Opportunity_Lookup__r.Co_borrower_First_Name__c ?
      oppData.Opportunity_Lookup__r.Co_borrower_First_Name__c + ' ' +
      oppData.Opportunity_Lookup__r.Co_borrower_Last_Name__c :
      null,
    customerPhone: oppData.Opportunity_Lookup__r.Primary_Contact_Phone__c,
    customerEmail: oppData.Opportunity_Lookup__r.Email__c,
    customerAddress: oppData.Opportunity_Lookup__r.Project_Address__c + ', ' +
      oppData.Opportunity_Lookup__r.Project_City__c + ', ' +
      oppData.Opportunity_Lookup__r.Project_State__c,
    ECName: ecData.Name,
    ECPhone: ecData.Phone,
    ECEmail: ecData.Email,
    // Chart Data Arrays
    energyCostsData: generateEnergyCostsData(+oppData.Pre_Solar_Monthly_Payment__c, utilityPriceEscalator),
    investmentIntoSolarData: generateInvestmentIntoSolarData(solarCostConstant, utilityPriceEscalator),
    // Dynamic consumption production version
    // consumptionProductionData: generateConsumptionProductionData(
    //   +oppData.Opportunity_Lookup__r.Estimated_Current_Usage_kWh__c,
    //   +oppData.Estimated_Production_1st_Year__c
    // ),
    // Fixed consumption production version
    consumptionProductionData: generateConsumptionProductionData(
      constantYearlyPowerConsumption,
      constantYearlySolarProduction
    ),
    // Image URLs
    customSolarDesignImageURL: EPFRData.Current_Design_3D_Render_URL__c,
    currentSatelliteImageURL: EPFRData.Current_Satellite_Image_URL__c,
    // Hardcoded Constants
    solarCostConstant,
    utilityPriceEscalator,
    utilityCostPerKwh,
    solar1CostPerKwh,
    solar2CostPerKwh
  };
}
