import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { endpointURLs } from '../CommissionPDFComponents/helpers/endpointURLs';
import FormatDollar from '../CommissionPDFComponents/helpers/HelperFunctions';
import {styled, ThemeProvider} from 'styled-components';
import Theme from '../CommissionPDFComponents/styles/Theme';
import GlobalStyle from '../CommissionPDFComponents/styles/GlobalStyle';
import StyleReset from '../CommissionPDFComponents/styles/StyleReset';
import Header from '../CommissionPDFComponents/components/Header';
import EarningsSection from '../CommissionPDFComponents/components/EarningsSection';
import DetailsSection from '../CommissionPDFComponents/components/DetailsSection';


const LineBreak = styled.div`
  width: 80%;
  height: 0.25rem;
  background-color: #333;
  margin: auto;
`

const SectionTitle = styled.h2`
  color: #333;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 2rem;
  text-transform: uppercase;
`

const CommissionPDFPage = () => {
  const [commData, setCommData] = useState();
  const { employeeName, dateStart, dateEnd } = useParams();
  const startDate = dateStart ? dateStart : new Date(2019, 6, 20).toISOString();
  const endDate = dateEnd ? dateEnd : new Date().toISOString();
  console.log(startDate, endDate)

  useEffect(() => {
    const getCommissionsData = async () => {
      try {
        const response = await fetch(`${endpointURLs.COMM_DATA_PROD}?employeeName=${employeeName}&dateStart=${startDate}&dateEnd=${endDate}`);
        const commissionsData = await response.json();
        setCommData(commissionsData);
      } catch (error) {
        console.error(error);
      };
    };
    getCommissionsData();
  }, []);

  return (
    <ThemeProvider theme={Theme}>
      <StyleReset />
      <GlobalStyle />
      <Header employeeName={employeeName} dateStart={startDate} dateEnd={endDate} />
      <LineBreak />
      <SectionTitle>Summary</SectionTitle>
      {commData && <EarningsSection commData={commData} employeeName={employeeName} />}
      <LineBreak />
      <SectionTitle>Details</SectionTitle>
      {commData && <DetailsSection commData={commData} employeeName={employeeName} />}
    </ThemeProvider>
  )
}

export default CommissionPDFPage
