export const HTOMainTheme = {
  pageBgColor: 'transparent',
  pageTextColor: '#343434',

  panelBgColor: 'rgb(246, 245, 247)',

  headingBgColor: 'rgb(31, 76, 124)',
  headingBgLightColor: 'rgb(50, 116, 181)',
  headingTextColor: 'white',

  tableBgMainLightColor: 'rgb(186, 223, 252)',
  tableBgMainDarkColor: 'rgb(115, 191, 249)',
  tableMainTextColor: 'black',

  tableBgAltLightColor: 'rgb(165, 247, 106)',
  tableBgAltDarkColor: 'rgb(129, 213, 82)',

  slideBottomMargin: '4rem',

  slideHeadingSize: '4rem',

  slideClassName: 'proposal-slide'
}
