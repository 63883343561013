import styled, { useTheme } from 'styled-components';
import InvestmentIntoSolarAreaChart from '../HTOCharts/InvestmentIntoSolarAreaChart';
import InvestmentIntoSolarLegend from '../HTOCharts/InvestmentIntoSolarLegend';
import SolarInvestmentChartImage from '../../images/HTOImages/investment-into-solar-chart-image.png';
import { useRef } from 'react';
import useOnScreen from '../../functions/useOnScreen';

const SlideContainer = styled.section`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: ${props => props.theme.slideBottomMargin};
  width: 100%;
  padding: 1rem;
  margin: 8rem 0;

  h1 {
    font-size: 2.5rem;
    padding: 1rem 2rem;
    text-align: center;
  }
`

const ChartWrapper = styled.div`
  max-width: 57rem;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`

export default function HTOInvestmentIntoSolarSlide({chartData}) {
  const theme = useTheme();

  // const chartOnScreenRef = useRef(null);
  // const chartIsOnScreen = useOnScreen(chartOnScreenRef);

  return (
    <SlideContainer className={theme.slideClassName}>
      <h1>TURN YOUR POWER BILL INTO AN<br/>INVESTMENT WITH NO UPFRONT COST</h1>
      {/* <ChartWrapper ref={chartOnScreenRef}>
        <InvestmentIntoSolarAreaChart chartData={chartData} height={500} width={750} chartIsOnScreen={chartIsOnScreen} />
        <InvestmentIntoSolarLegend />
      </ChartWrapper> */}
      <ChartWrapper>
        <img src={SolarInvestmentChartImage} alt='chart of solar savings' />
      </ChartWrapper>
    </SlideContainer>
  )
}