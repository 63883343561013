import styled from "styled-components";

const StyledHeader = styled.header`
  margin-bottom: 2rem;
  color: #333;
  text-align: center;

  h1 {
    font-size: 2rem;
    margin: 3rem 1rem 2rem 1rem;
    text-transform: uppercase;
    font-weight: 600;
  }
`;

const SubsectionContainer = styled.div`
  display: flex;
  justify-content: center;
`

const Subsection = styled.div`
  padding: 0 4rem;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;

  span {
    color: #05a2ff;
    text-transform: uppercase;
    font-weight: 600;
  }
`

const Header = ({ employeeName, dateStart, dateEnd }) => {

  return (
    <StyledHeader>
      <h1>Commissions Report</h1>
      <SubsectionContainer>
        <Subsection>
          <span>{employeeName}</span>
        </Subsection>
        <Subsection>
          <span>
            {new Date(dateStart).toLocaleString('en-US', { dateStyle: 'short' })}
            —
            {new Date(dateEnd).toLocaleString('en-US', { dateStyle: 'short' })}
          </span>
        </Subsection>
      </SubsectionContainer>
    </StyledHeader>
  );
};

export default Header;
