import { monthNames } from "./FunctionsInGeneral";

const SEC_IN_MINUTE = 60;
const SEC_IN_HOUR = SEC_IN_MINUTE * 60;
export const SEC_IN_DAY = SEC_IN_HOUR * 24;
export const SEC_IN_WEEK = SEC_IN_DAY * 7;
export const DATA_POINTS = 7;
// Seconds in month is a little more complicated.

export function dateToEpochSec(date)
{
	return Math.floor(date.getTime() / 1000);
}

export function epochNowSec()
{
	return Math.floor(Date.now() / 1000);
}

export const empOptions = {
    responsive: true,
    maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    font: {
                        size: 14
                    }
                }
            },
            y: {
                grid: {
                    display: true,
                },
                ticks: {
                    callback: function(value, index, ticks) {
                        return value + " kWh";
                    }
                }
            }
        },
        animation: {
                duration: 250,
        },
        onResize: {
        },
        plugins: {
            legend:
            {
                display: false,
                position: "top",
            },
            title: {
                display: false,
                text: "Your Home Energy Use (kWh)"
            },
            tooltip: {
                    enabled: false
            },
        }
}
  
export const empOptionsDefault = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            grid: {
                display: false,
            },
            ticks: {
                font: {
                    size: 14
                }
            }
        },
        y: {
            grid: {
                display: true,
            },
            ticks: {
                callback: function(value, index, ticks) {
                    return value + " kWh";
                }
            }
        }
    },
    animation: {
        duration: 3000,
    },
    onResize: {
    },
    plugins: {
        legend: {
            display: false,
            position: "top",
        },
        title: {
            display: false,
            text: "Your Home Energy Use (kWh)"
        },
        tooltip: {
            enabled: false
        },
    }
}

export const labels = ["", "", "", "", ""];

export function epochSecToDate(epoch)
{
	return new Date(epoch * 1000);
}

export function isDST(d) 
{
    let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
    let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
    return Math.max(jan, jul) !== d.getTimezoneOffset();    
}

export function epochToUTCLabel(epoch, timeWindow)
{
	let date = epochSecToDate(epoch);
	if(timeWindow == "day")
	{
		return monthNames[date.getUTCMonth()] + " " + date.getUTCDate();
	}
	else if(timeWindow == "week")
	{
		// Account for Emporia sending the values at 5am
		// Without this the weekly labels would show Monday instead of Sunday.
        let offset = 0;
        if(isDST(date))
            offset = 1;
		let adjustedDate = date.setHours(date.getHours() - 6 + offset);
		let array = new Date(adjustedDate).toUTCString().split(" ");

		return array[0].slice(0, -1) + " " + array[2] + " " + array[1];
	}
	else if(timeWindow == "month")
	{
		let array = date.toUTCString().split(" ");
		return array[2] + " " + array[3];
	}
}

export function prepEmporiaData(data, timeWindow, empBarColor)
{
	let times = data[0];
	// Divide by 1000 to get kWh
	let values = data[1].map(value => value/1000);
	let labels = [];

	for(let i = 0; i < times.length; i++)
	{
		labels.push(epochToUTCLabel(times[i], timeWindow))
	}

	let formattedData = 
	{
		labels,
		datasets: [
		  {
			label: "Energy",
			data: values,
			borderColor: empBarColor[0],
					borderWidth: 2,
			backgroundColor: empBarColor[1]
		  }
		]
	};
	return formattedData;
}

export const barsBlue = ["rgba(29, 163, 252, 1.0)", "rgba(29, 163, 252, 0.9)"]
export const barsRed = ["rgba(252, 42, 29, 1.0)", "rgba(252, 42, 29, 0.9)"]

export let empDefault = {
	labels,
	datasets: [
		{
			label: "",
			data: [70, 50, 65, 45, 80],
			borderColor: "rgb(56, 207, 42)",
			backgroundColor: barsRed[0]
		}
	]
}