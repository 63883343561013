const Theme = {
  // Background colors
  pageBackground: '#eee',
  navBackground: '#222',

  // Text colors
  pageText: '#222',
  navTextDefault: '#eee',
  navTextHighlight: '#3d9be9',

  // Borders
  borderRadius: '8px',
};

export default Theme;
