import styled, { useTheme } from 'styled-components';
import SolarOffsetDonutChart from '../HTOCharts/SolarOffsetDonutChart';
import SolarOffsetLegend from '../HTOCharts/SolarOffsetLegend';
import ConsumptionProductionMixedChart from '../HTOCharts/ConsumptionProductionMixedChart';
import ConsumptionProductionLegend from '../HTOCharts/ConsumptionProductionLegend';
import { useEffect, useRef } from 'react';
import useOnScreen from '../../functions/useOnScreen';

const SlideContainer = styled.section`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: ${props => props.theme.slideBottomMargin || '0'};
  margin: 8rem 0;

  h1 {
    height: 4rem;
    font-size: ${props => props.theme.slideHeadingSize};
    text-transform: uppercase;
    margin-bottom: 2rem;
  }
`

const SubsectionContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  width: 100%;
  flex: 1;
`

const Subsection = styled.div`
  flex: 1;
  min-width: 375px;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: 1rem;

  h2 {
    background-color: ${props => props.theme.headingBgColor};
    color: ${props => props.theme.headingTextColor};
    font-size: 1.5rem;
    font-weight: 400;
    text-transform: uppercase;
    width: 100%;
    margin-bottom: .5rem;
    padding: 0.5rem 2rem;
  }
`

const ChartWrapper = styled.div`
  width: 100%;
  position: relative;
`

const DetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  margin-bottom: 1rem;
  width: 100%;
  height: ${props => props.height};

  h3 {
    font-size: 1rem;
    text-transform: uppercase;
  }
  p {
    font-size: 1.5rem;
  }
`

const ChartHeading = styled.p`
  height: ${props => props.height};
  line-height: ${props => props.height / 2};
  margin-bottom: 1rem;
`

export default function HTOSystemOverviewSlide({chartData}) {
  const theme = useTheme();

  const solarOffsetOnScreenRef = useRef(null);
  const solarOffsetIsOnScreen = useOnScreen(solarOffsetOnScreenRef);

  const consumptionProductionOnScreenRef = useRef(null);
  const consumptionProductionIsOnScreen = useOnScreen(consumptionProductionOnScreenRef);

  return (
    <SlideContainer className={theme.slideClassName}>
      <h1>Solar System Overview</h1>
      <SubsectionContainer>
        <Subsection>
          <h2>Your Solar System Details</h2>
          <DetailsGrid height={'6rem'}>
            <h3>System Size (DC)</h3>
            <h3>Estimated Year 1 Production</h3>
            <p>{chartData.systemSize}</p>
            <p>{chartData.yearlySolarProduction.toLocaleString('en-US')} kWh</p>
          </DetailsGrid>
          <h2>Your Estimated Annual Solar System Offset</h2>
          <DetailsGrid height={'4rem'}>
            <h3>Solar Power</h3>
            <h3>Utility Power</h3>
            <p>{chartData.solarPowerPercent}%</p>
            <p>{chartData.utilityPowerPercent}%</p>
          </DetailsGrid>
          <ChartWrapper ref={solarOffsetOnScreenRef}>
            <SolarOffsetDonutChart chartData={chartData} height={500} width={750} chartIsOnScreen={solarOffsetIsOnScreen}/>
          </ChartWrapper>
        </Subsection>
        <Subsection>
          <h2>System Hardware</h2>
          <DetailsGrid height={'6rem'}>
            <h3>Premium Panels</h3>
            <h3>Inverter</h3>
            <p>{chartData.panelQuantity} {chartData.panelManufacturer}</p>
            <p>{chartData.inverterQuantity} {chartData.inverterManufacturer}</p>
            <p>{chartData.panelName}</p>
            <p>{chartData.inverterName}</p>
          </DetailsGrid>
          <h2>Typical Consumption and<br/> Production</h2>
          <ChartHeading height={'4rem'}>
            Replace the electricity that you bought last year<br/> with the power of the sun
          </ChartHeading>
          <ChartWrapper ref={consumptionProductionOnScreenRef}>
            <ConsumptionProductionMixedChart
              chartData={chartData}
              height={500}
              width={750}
              chartIsOnScreen={consumptionProductionIsOnScreen}
            />
          </ChartWrapper>
        </Subsection>
      </SubsectionContainer>
    </SlideContainer>
  )
}
