import React from 'react';
import styled from 'styled-components';
import { formatPhone } from '../functions/FunctionsInGeneral';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 40px;
`

const SubContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: ${props => props.marginBottom};
`

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    width: 100%;

    p {
        color: #555555;
        font-size: 14px;
        text-align: ${props => props.alignText};
        font-family: 'Graphik';
    }
`

const PersonalInfo = (props) => {
    if (!props.referralData) {
        return null;
    }

    const oppData = props.referralData.oppData;
    const ecData = props.referralData.ecData;

    return (
        <Container>
            <SubContainer marginBottom="20px">
                <InfoContainer alignText="left">
                    <p>{formatPhone(oppData.Primary_Contact_Phone__c)}</p>
                    <p>{oppData.Email__c}</p>
                </InfoContainer>
                <InfoContainer alignText="right">
                    <p>Energy Consultant</p>
                </InfoContainer>
            </SubContainer>
            <SubContainer marginBottom="0">
                <InfoContainer alignText="left">
                    <p>{oppData.Project_Address__c}</p>
                    <p>{oppData.Project_City__c} {oppData.Project_State__c} {oppData.Project_Zip_Code__c}</p>
                    <p>United States</p>
                </InfoContainer>
                <InfoContainer alignText="right">
                    <p>{ecData.Name}</p>
                    <p>{formatPhone(ecData.Phone)}</p>
                    <p>{ecData.Email}</p>
                </InfoContainer>
            </SubContainer>
        </Container>
    )
};

export default PersonalInfo;