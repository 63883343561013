import { upload } from '@testing-library/user-event/dist/upload';
import React from 'react';
import { useState, useEffect } from 'react';
import './UploadButton.css';

import * as Constants from './functions/Constants'

function UploadButton(props) {

    const [fileData, setFileData] = useState();
    const [fileExtension, setFileExtension] = useState('.png')
    async function fileChanged(event, text) {
        if (event.target.files[0].size > 35800000) {
            setTimeout(() => {
                props.showFailurePopup(true)
            }, 700)
            return
        }
        let extensionIndex = event.target.files[0].name.lastIndexOf('.')
        let extension = event.target.files[0].name.substring(extensionIndex)
        setFileExtension(extension)
        let base64 = await getBase64(event.target.files[0])
        fetch(`${Constants.JS_BACKEND_URL}/fileUpload`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'oppId': props.sfData.opportunityId,
                    'projectId': props.sfData.everlightProjectFileId,
                    'documentTitle': 'Home Insurance Upload',
                    'fileName': 'Home Insurance Upload' + fileExtension,
                },
                body: JSON.stringify({ fileData: base64 }),
            })
        .then(response => response.json())
        .then((data) => {
            // If no error, immediately show as completed (Double check vs sfData later)
            if(!data.error)
            {
                props.setCompletedInsurance(true)
                setTimeout(() => {
                    props.updateSFdata()
                    .then((latestSFdata) => {
                        if(!latestSFdata.customerInsuranceUploadUrl) // Send back to rejected state if SF doesn't agree
                            props.setCompletedInsurance(false)
                    })
                    .catch(() => {
                        // TODO: error handling for if SF call fails
                    })
                }, 3000)
            }
            else
                props.showFailurePopup(true)
        })
        .catch((error) => {
            // TODO - non-filesize related error
            props.showFailurePopup(true)
        })
    }

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
                if ((encoded.length % 4) > 0) {
                    encoded += '='.repeat(4 - (encoded.length % 4));
                }
                resolve(encoded);
            };
            reader.onerror = error => reject(error);
        });
    }


    return (
        <>
            <div className={props.completedInsurance ? '' : 'uploadButton'}>
                <div className="status"></div>
                <label className="">
                    <input type="file" name="input-name" style={{ display: "none" }} onChange={e => { fileChanged(e, props.text) }} />
                    <div>{props.text}</div>
                </label>
            </div>
        </>
    );
}

export default UploadButton;