import React from 'react';
import '../App.css';

import { formatMoney } from '../functions/FunctionsInGeneral';

import BarChart from '../BarChart';
import TimeBarAP from '../TimeBarAP';
import InstallDateSurvey from '../InstallDateSurvey';
import HOASurveyForm from '../HOASurveyForm';
import AlertBanner from '../AlertBanner';

import { useWindowWidth } from '../functions/useWindowWidth';

const EnergyProductionPage = (props) => {
    const windowWidth = useWindowWidth()

    return(
        <>
            <div className="chart-page-container" style={props.ecuId.length > 0 && !props.apCallFailure && props.ecuId != "flagged as undefined" ? {opacity: "1"} : {opacity: "0.4"}}>
                {props.showInstallDateSurvey ? <InstallDateSurvey installData={props.sfData} setShowInstallDateSurvey={props.setShowInstallDateSurvey} accountIdv3={props.accountId} updateSFdata={props.updateSFdata} /> : null}

                {/* {props.showHOASurvey ? <HOASurveyForm setShowHOASurvey={props.setShowHOASurvey} accountId={props.accountId} accountIdv3={props.accountId} updateSFdata={props.updateSFdata} sfData={props.sfData} /> : null}	 */}

                {props.alertData.text ? <AlertBanner alertData={props.alertData} setShowHOASurvey={props.setShowHOASurvey} setShowInstallDateSurvey={props.setShowInstallDateSurvey} updateSFdata={props.updateSFdata} /> : null}
                
                {
                    props.apData == props.apBlank || props.apData == props.apDefault || props.timeWindowAP == "year"
                    ? // No button at all.
                        <>
                            <h1 className="page-title">Your Home Energy Production (kWh)</h1>

                            {windowWidth > 600 ? <div className='margin60px'></div>: <div className='margin20px'></div>}
                            {
                                (!props.showOverlay)
                                ?
                                    props.timeWindowAP == "year"
                                    ?
                                        <BarChart data={props.apDataYear} options={props.apOptions} />
                                    :
                                        <BarChart data={props.apData} options={props.apOptions} />
                                :
                                null
                            }
                            <TimeBarAP timeWindowAP={props.timeWindowAP} setTimeWindowAP={props.setTimeWindowAP}/>
                        </>
                    : // Either blue or gray button - or no button if no data from last year.
                        <>
                            <h1 className="page-title">Your Home Energy Production (kWh)</h1>
                            <div className='margin10px'></div>
                            {

                                props.apData.labels.length <= 12 ? (
                                    <div id="apSystemsYearSwitcherNotAButton">
                                    {props.apSystemsYearOffset === 0 ? new Date().getFullYear() : new Date().getFullYear() - 1}
                                    </div>
                                ) : !props.apData.datasets[0].data[11] ? (
                                    <div id="apSystemsYearSwitcherGray">
                                    {props.apSystemsYearOffset === 0 ? new Date().getFullYear() : new Date().getFullYear() - 1}
                                    </div>
                                ) : (
                                    <div id="apSystemsYearSwitcher">
                                    {windowWidth > 600 && !props.userIsOnMobilePhone ? (
                                        <select
                                        value={props.apSystemsYearOffset === 0 ? new Date().getFullYear() : new Date().getFullYear() - 1}
                                        onChange={(e) => {
                                            const selectedYear = parseInt(e.target.value);
                                            const yearOffset = selectedYear === new Date().getFullYear() ? 0 : -1;
                                            props.setApSystemsYearOffset(yearOffset);
                                        }}
                                        >
                                        <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
                                        <option value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</option>
                                        </select>
                                    ) : (
                                        
                                            props.apData.labels.length <= 12
                                            ?
                                                <div id="apSystemsYearSwitcherNotAButton">{ props.apSystemsYearOffset == 0 ? (new Date()).getFullYear() : (new Date()).getFullYear() - 1 }</div>
                                            :
                                                !props.apData.datasets[0].data[11]
                                                ?
                                                    <div id="apSystemsYearSwitcherGray">{ props.apSystemsYearOffset == 0 ? (new Date()).getFullYear() : (new Date()).getFullYear() - 1 }</div>
                                                :
                                                    <div id="apSystemsYearSwitcher" onClick={ () => {props.apSystemsYearOffset == 0 ? props.setApSystemsYearOffset(-1) : props.setApSystemsYearOffset(0) }}>{ props.apSystemsYearOffset == 0 ? (new Date()).getFullYear() : (new Date()).getFullYear() - 1 }</div>
                                        
                                    )}
                                    </div>
                                )
                            }
                            {
                                (!props.showOverlay)
                                ?
                                    <BarChart data={props.apDataTrimmed} options={props.apOptions} />
                                :
                                null
                            }
                            <TimeBarAP timeWindowAP={props.timeWindowAP} setTimeWindowAP={props.setTimeWindowAP}/>
                        </>
                }
            </div>
                
            {
                // if ECU ID exists and there was no AP call failure
                props.ecuId.length > 0 && !props.apCallFailure && props.ecuId != "flagged as undefined"
                ?
                    null
                :
                    <>
                        <div className="box-center bg-gray-200 opacity-80 pageOverlay">
                        </div>
                        <div className="box-center pageOverlay">
                            <div className="pageOverlayTextContainer">
                                    <h4 className="opacity-100 pageOverlayText apNoDataText">
                                        <div className="textBlue">Your Everlight solar energy production will show here soon.</div>
                                        {
                                            props.sfData.twentyFiveYearSavings
                                            ?
                                            <><br/>Your estimated 25-year savings <br/> <strong className="greenText">{formatMoney(props.sfData.twentyFiveYearSavings)}</strong></>
                                            :
                                            null
                                        }
                                    </h4>
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default EnergyProductionPage