import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { LinearGradient } from '@visx/gradient';
import { useContext, useEffect, useMemo, useState } from 'react';
import { animate, motion } from 'framer-motion';
import { ScaleSVG } from '@visx/responsive';
import styled, { useTheme } from 'styled-components';
import { AnimationContext } from '../HTOContext/AnimationContext';
import { AxisBottom } from '@visx/axis';

const StyledRect = styled(motion.rect)`
  transform-origin: bottom !important;
`

const StyledGroup = styled(motion.g)`
  transform-origin: bottom !important;
`

export default function SolarSavingsBarChart({chartData, width, height, chartIsOnScreen}) {
  // animation
  const animationEnabled = useContext(AnimationContext);

  const animatedElements = document.querySelectorAll('.solar-savings-chart-animated');
  
  useEffect(() => {
    if (chartIsOnScreen && animationEnabled) {
      animatedElements.forEach(element => {
        animate(element, { scaleY: [0, 1] }, { type: "spring", mass: 0.55 });
      })
    }
  }, [chartIsOnScreen]);

  //data
  const data = [
    {
      label: 'Utility Power',
      cost: chartData.utilityCostPerKwh
    },
    {
      label: 'Solar Option #1',
      cost: chartData.solar1CostPerKwh
    },
    {
      label: 'Solar Option #2',
      cost: chartData.solar2CostPerKwh
    },
  ]

  const chartMargins = { top: 40, right: 20, bottom: 20, left: 20 };

  // colors
  const theme = useTheme();
  const background = theme.panelBgColor;
  const axisColor = theme.pageTextColor;
  const labelColors = [
    '#F33016',
    '#2176AE',
    '#5FAB8F'
  ];
  const barColors = [
    'url(#utility-cost-gradient)',
    'url(#solar1-cost-gradient)',
    'url(#solar2-cost-gradient)'
  ];

  // accessors
  const getLabel = (d) => d.label;
  const getCost = (d) => d.cost;
  
  // scales, memoize for performance
  const chartWidth = width || 750;
  const chartHeight = height || 500;

  const xMax = chartWidth - chartMargins.left - chartMargins.right;
  const yMax = chartHeight - chartMargins.top - chartMargins.bottom;

  const xScale = useMemo(
    () =>
      scaleBand({
        range: [0, xMax],
        round: true,
        domain: data.map(getLabel),
        padding: 0.25,
      }),
    [xMax],
  );
  const yScaleDomainPadding = 0.0125;
  const yScale = useMemo(
    () =>
      scaleLinear({
        range: [yMax, 0],
        round: true,
        domain: [0, Math.max(...data.map(getCost)) + yScaleDomainPadding],
      }),
    [yMax],
  );

  // update scale output dimensions
  xScale.rangeRound([0, xMax]);
  yScale.range([yMax, 0]);

  // position constants
  const gridRowsPadding = 20;
  const textLabelPadding = 10;

  return width < 10 ? null : (
    <ScaleSVG width={width} height={height}>
      <svg>
        <rect width={width} height={height} fill={background} rx={14} />
        <LinearGradient id={'utility-cost-gradient'} from="#F33016" to="#e9260c79"/>
        <LinearGradient id={'solar1-cost-gradient'} from="#2176AE" to="#2176ae8f"/>
        <LinearGradient id={'solar2-cost-gradient'} from="#5FAB8F" to="#5fab8f88"/>
        <GridRows
          top={chartMargins.top}
          left={chartMargins.left + gridRowsPadding}
          scale={yScale}
          width={xMax - gridRowsPadding * 2}
          height={yMax}
          stroke={axisColor}
          opacity={.5}
          strokeDasharray="1,3"
          numTicks={4}
        />
        <Group top={chartMargins.top} left={chartMargins.left}>
          {data.map((d, i) => {
            const label = getLabel(d);
            const barWidth = xScale.bandwidth();
            const barHeight = yMax - (yScale(getCost(d)) ?? 0);
            const barX = xScale(label);
            const barY = yMax - barHeight;
            return (
              <g key={`savings-bar-${label}`}>
                <StyledRect
                  className='solar-savings-chart-animated'
                  x={barX}
                  y={barY}
                  width={barWidth}
                  height={barHeight}
                  fill={barColors[i]}
                  rx={14}
                />
                <StyledGroup className='solar-savings-chart-animated'>
                  <text
                    textAnchor='middle'
                    fontSize={'2rem'}
                    x={barX + xScale.bandwidth() / 2}
                    y={barY - textLabelPadding}
                    fill={labelColors[i]}
                    fontWeight={600}
                  >
                    ${d.cost}
                  </text>
                </StyledGroup>
              </g>
            );
          })}
        </Group>
      </svg>
    </ScaleSVG>
  );
}
