import styled, { useTheme } from 'styled-components';
import EverlightLogo from '../../images/HTOImages/EverlightLogoDarkGray.svg'
import { formatPhone } from '../../functions/FunctionsInGeneral';

const SlideContainer = styled.section`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-height: ${props => props.isPDFVersion ? '1024px' : '100vh'};
  padding: 1rem;

  img {
    fill: 'red';
    margin-bottom: -2rem;
    width: 15rem;
  }
  h1 {
    font-family: Comfortaa;
    font-size: 6rem;
    text-align: center;
  }
  h2 {
    font-size: 3rem;
    text-align: center;
    text-transform: uppercase;
  }
`

const ContactSection = styled.section`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 5rem 5rem 0 5rem;
  width: 100%;
`

const ContactSubsection = styled.div`
  line-height: 2rem;
  min-width: 15rem;
  padding-bottom: 2rem;

  h3 {
    font-size: 1rem;
    font-weight: 800;
    text-transform: uppercase;
  }
`

export default function HTOTitleSlide({chartData, isPDFVersion}) {
  const theme = useTheme();

  const hasCoBorrower = (name) => {
    if (name && name !== 'X X' && name !== '') {
      return true
    }
    return false
  };

  return (
    <SlideContainer isPDFVersion={isPDFVersion} className={theme.slideClassName}>
      <img src={EverlightLogo} alt='everlight solar logo'/>
      <h1>Everlight Solar</h1>
      <h2>Custom Solar Energy Design</h2>
      <ContactSection>
        <ContactSubsection>
          <h3>Prepared For:</h3>
          <p>{chartData.customerName}{hasCoBorrower(chartData.coborrowerName) ? ` & ${chartData.coborrowerName}` : ''}</p>
          <p>{formatPhone(chartData.customerPhone)}</p>
          <p>{chartData.customerEmail}</p>
          <p>{chartData.customerAddress}</p>
        </ContactSubsection>
        <ContactSubsection>
          <h3>Energy Consultant:</h3>
          <p>{chartData.ECName}</p>
          <p>{formatPhone(chartData.ECPhone)}</p>
          <p>{chartData.ECEmail}</p>
        </ContactSubsection>
      </ContactSection>
    </SlideContainer>
  )
}
