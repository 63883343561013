import styled from "styled-components";
import FormatDollar  from "../helpers/HelperFunctions";

const Container = styled.section`
  width: 80%;
  margin: 2rem auto;
`

const DetailsList = styled.ul`
  padding-left: 2rem;
`;

const GridItem = styled.li`
  margin-bottom: 2rem;

  span {
    color: #05a2ff;
    text-transform: uppercase;
    font-weight: 600;
  }
  p {
    margin-top: 0.5rem;
    color: #333;
  }
`;

const Total = styled.div`
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  span {
    color: #329e0d;
    font-weight: 600;
    text-transform: uppercase;
  }
  p {
    margin-top: 0.5rem;
    color: #333;
  }
`;

const EarningsSection = ({ commData }) => {
  return (
    <Container>
      <DetailsList>
        {commData.totals.ECFrontend !== 0 &&
          <GridItem>
            <span>EC Frontend Commissions</span>
            <p>{FormatDollar(commData.totals.ECFrontend)}</p>
          </GridItem>
        }
        {commData.totals.ECBackend !== 0 &&
          <GridItem>
            <span>EC Backend Commissions</span>
            <p>{FormatDollar(commData.totals.ECBackend)}</p>
          </GridItem>
        }
        {commData.totals.ECProductionBonus !== 0 &&
          <GridItem>
            <span>EC Production Bonuses</span>
            <p>{FormatDollar(commData.totals.ECProductionBonus)}</p>
          </GridItem>
        }
        {commData.totals.SDRPrimary !== 0 &&
          <GridItem>
            <span>SDR Primary / EC Self-gen Commission</span>
            <p>{FormatDollar(commData.totals.SDRPrimary)}</p>
          </GridItem>
        }
        {commData.totals.SDRTLOverride !== 0 &&
          <GridItem>
            <span>SDR Team Lead Override Bonuses</span>
            <p>{FormatDollar(commData.totals.SDRTLOverride)}</p>
          </GridItem>
        }
        {commData.totals.ECGiftCard !== 0 &&
          <GridItem>
            <span>EC Gift Card</span>
            <p>{FormatDollar(commData.totals.ECGiftCard)}</p>
          </GridItem>
        }
        {commData.totals.SDRProductionBonus !== 0 &&
          <GridItem>
            <span>SDR Production Bonuses</span>
            <p>{FormatDollar(commData.totals.SDRProductionBonus)}</p>
          </GridItem>
        }
        {commData.totals.SDRGiftCard !== 0 &&
          <GridItem>
            <span>SDR Gift Card</span>
            <p>{FormatDollar(commData.totals.SDRGiftCard)}</p>
          </GridItem>
        }
      </DetailsList>
      <Total>
        <span>Total</span>
        <p>{FormatDollar(Object.values(commData.totals).reduce((total, current) => total + current, 0))}</p>
      </Total>
    </Container>
  );
};

export default EarningsSection
