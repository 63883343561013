import styled from "styled-components";
import FormatDollar from "../helpers/HelperFunctions";

const List = styled.ol`
  width: 80%;
  margin: auto;
`;

const ListItem = styled.li`
  background: #ddd;
  border-radius: 1rem;
  position: relative;
  margin-bottom: 2rem;
`;

const Header = styled.header`
  background: #333;
  color: white;
  display: grid;
  grid-template-columns: 1fr 30% 20%;
  padding: 0.5rem 1rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  :last-child {
    text-align: right;
  }
`;

const TeamMemberList = styled.ul`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
`;

const SystemSize = styled.p`
  border-bottom: 1px solid gray;
  padding: 0.5rem 1rem;
`

const EarningsBreakdown = styled.div`
  display: grid;
  grid-template-columns: 35% 8%;
  padding: 1rem;
  row-gap: 0.5rem;
  span {
    color: #05a2ff;
    font-weight: 600;
  }
`;

const OppID = styled.p`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  color: #777;
`;

const DetailsSection = ({ commData, employeeName }) => {
  const stageDataArray = Object.entries(commData.stageData).map(e => e[1]);
  const filteredArray = stageDataArray.filter(row => {
    return (
      (row['EC Commission Frontend'] && row['EC'] === employeeName) ||
      (row['EC Commission Backend'] && row['EC'] === employeeName) ||
      (row['EC Production Bonus'] && row['EC'] === employeeName) ||
      (row['SDR TL Override'] && row['SDR.Reporting_To<->FROM<->BambooHR'] === employeeName) ||
      (row['EC Gift Card'] && row['EC'] === employeeName) ||
      (row['SDR Commission'] && row['SDR'] === employeeName) ||
      (row['SDR Production Bonus'] && row['SDR'] === employeeName) ||
      (row['SDR Gift Card'] && row['SDR'] === employeeName)
    )
  });

  return (
    <List>
      {filteredArray.map((row, i) => {
        return (
          <ListItem key={row['Opportunity ID'] + '-' + i}>
            <Header>
              <span>{row['Opportunity Name']}</span>
              <span>{row['Stat Type']}</span>
              <span>{new Date(row['Magic Date']).toLocaleString('en-US', { dateStyle: 'short' })}</span>
            </Header>
            <TeamMemberList>
              <li>
                <span>EC: </span>
                <span>{row['EC']}</span>
              </li>
              <li>
                <span>SDR: </span>
                <span>{row['SDR']}</span>
              </li>
              <li>
                <span>SDR TL: </span>
                <span>{row['SDR.Reporting_To<->FROM<->BambooHR']}</span>
              </li>
            </TeamMemberList>
            <SystemSize>System Size: {row['DC System Size']} kW</SystemSize>
            <EarningsBreakdown>
              {(row['EC Commission Frontend'] && row['EC'] === employeeName) &&
                <>
                  <span>EC Commission Frontend</span>
                  <p>{FormatDollar(row['EC Commission Frontend'])}</p>
                </>
              }
              {(row['EC Commission Backend'] && row['EC'] === employeeName) &&
                <>
                  <span>EC Commission Backend</span>
                  <p>{FormatDollar(row['EC Commission Backend'])}</p>
                </>
              }
              {(row['EC Production Bonus'] && row['EC'] === employeeName) &&
                <>
                  <span>EC Production Bonus</span>
                  <p>{FormatDollar(row['EC Production Bonus'])}</p>
                </>
              }
              {(row['SDR TL Override'] && row['SDR.Reporting_To<->FROM<->BambooHR'] === employeeName) &&
                <>
                  <span>SDR Team Lead Override</span>
                  <p>{FormatDollar(row['SDR TL Override'])}</p>
                </>
              }
              {(row['EC Gift Card'] && row['EC'] === employeeName) &&
                <>
                  <span>EC Gift Card</span>
                  <p>{FormatDollar(row['EC Gift Card'])}</p>
                </>
              }
              {(row['SDR Commission'] && row['SDR'] === employeeName) &&
                <>
                  <span>SDR Primary Commission / EC Self-gen Bonus</span>
                  <p>{FormatDollar(row['SDR Commission'])}</p>
                </>
              }
              {(row['SDR Production Bonus'] && row['SDR'] === employeeName) &&
                <>
                  <span>SDR Production Bonus</span>
                  <p>{FormatDollar(row['SDR Production Bonus'])}</p>
                </>
              }
              {(row['SDR Gift Card'] && row['SDR'] === employeeName) &&
                <>
                  <span>SDR Gift Card</span>
                  <p>{FormatDollar(row['SDR Gift Card'])}</p>
                </>
              }
            </EarningsBreakdown>
            <OppID>{row['Opportunity ID']}</OppID>
          </ListItem>
        )
      })}
    </List>
  );
};

export default DetailsSection
