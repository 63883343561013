import React from 'react';
import { Link } from 'react-router-dom';
import './ThreeButtons.css';


const ThreeButtonsOnlyOne = (props) => {
    return(
    <ul className='three-buttons'>
        <li className='three-buttons-refer'>
            <a className="whiteText" href="https://everlightsolar.com/customer-support/" target="_blank" rel='noreferrer'>Get Back My Savings</a>
        </li>
    </ul>
    );
}

export default ThreeButtonsOnlyOne;