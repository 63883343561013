import { AxisBottom } from '@visx/axis';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { AreaClosed, Bar } from '@visx/shape';
import { curveNatural } from '@visx/curve';
import { GradientOrangeRed, LinearGradient } from '@visx/gradient';
import { animate, motion } from "framer-motion";
import { ScaleSVG } from '@visx/responsive';
import { useTheme } from 'styled-components';
import { useContext, useEffect, useState } from 'react';
import { AnimationContext } from '../HTOContext/AnimationContext';

export default function ConsumptionProductionMixedChart({chartData, width, height, chartIsOnScreen}) {
  const data = chartData.consumptionProductionData;
  const keys = Object.keys(data[0]).filter((d) => d !== 'month');

  // animation curves
  const barDelayCurve = [0, 1, 2, 3, 4, 5, 5, 4, 3, 2, 1, 0];
  const barDelayFactor = 0.05;

  // animation
  const animationEnabled = useContext(AnimationContext);
  const scaleAnimationProps = (animationEnabled ?
    {
      initial: { scaleY: 0 },
      style: { originY: 1 },
    } :
    null
  );
  const opacityAnimationProps = (animationEnabled ?
    {
      initial: { opacity: 0 },
    } :
    null
  );

  const solarCurve = document.querySelector('#mixed-chart-solar-curve-animation-id');
  const utilityRects = document.querySelectorAll('.mixed-chart-utility-bar-animation-class');
  
  useEffect(() => {
    if (chartIsOnScreen && animationEnabled) {
      animate(solarCurve, { opacity: [0, 1] });
      utilityRects.forEach((rect, i) => {
        animate(rect, { scaleY: [0, 1] }, { type: "spring", mass: 0.5, delay: barDelayCurve[i] * barDelayFactor });
      })
    }
  }, [chartIsOnScreen]);

  // accessors
  const getMonth = d => d.month;
  const getConsumption = d => d.consumption;
  const getProduction = d => d.production;

  // colors
  const theme = useTheme();
  const background = theme.panelBgColor;
  const axisColor = theme.pageTextColor;

  // scales
  const monthScale = scaleBand({
    domain: data.map(getMonth),
    padding: 0.5,
  });
  const yScaleDomainPadding = (
    // Add 15% padding to top of chart, based on highest value
    Math.max(
      ...data.map(element => element.production),
      ...data.map(element => element.consumption)
    ) * 0.15
  );
  const powerScale = scaleLinear({
    domain: [0, Math.max(...data.map((d) => Math.max(...keys.map((key) => Number(d[key]))))) + yScaleDomainPadding],
  });

  const chartWidth = width || 750;
  const chartHeight = height || 500;
  const chartMargins = { top: 40, right: 0, bottom: 40, left: 0 };

  const xMax = chartWidth - chartMargins.left - chartMargins.right;
  const yMax = chartHeight - chartMargins.top - chartMargins.bottom;

  // update scale output dimensions
  monthScale.rangeRound([0, xMax]);
  powerScale.range([yMax, 0]);

  const gridRowsPadding = 20;

  return chartWidth < 10 ? null : (
    <ScaleSVG width={width} height={height}>
      <svg width={chartWidth} height={chartHeight}>
        <rect x={0} y={0} width={chartWidth} height={chartHeight} fill={background} rx={14} />
        <LinearGradient id={'consumption-gradient'} from="#116485" to="#168cdbc7"/>
        <LinearGradient id={'production-gradient'} from="#6fbc43" to="#98dd6f"/>
        <GradientOrangeRed id="solar-gradient" />
        <GridRows
          top={chartMargins.top}
          left={chartMargins.left + gridRowsPadding}
          scale={powerScale}
          width={xMax - gridRowsPadding * 2}
          height={yMax}
          stroke={axisColor}
          opacity={.5}
          strokeDasharray="1,3"
        />
        <motion.g 
          transform={`translate(${chartMargins.left + monthScale.bandwidth() / 2} ${chartMargins.top})`}
          {... opacityAnimationProps}
          id='mixed-chart-solar-curve-animation-id'
        >
          <AreaClosed
            data={data}
            x={(d) => monthScale(getMonth(d)) ?? 0}
            y={(d) => powerScale(getProduction(d)) ?? 0}
            yScale={powerScale}
            fill={'#fe9201'}
            curve={curveNatural}
          />
          <AreaClosed
            data={data}
            x={(d) => monthScale(getMonth(d)) ?? 0}
            y={(d) => powerScale(getProduction(d)) + 5 ?? 0}
            yScale={powerScale}
            fill={'url(#production-gradient)'}
            curve={curveNatural}
          />
        </motion.g>
        <Group top={chartMargins.top} left={chartMargins.left}>
          {data.map((d, i) => {
            const month = getMonth(d);
            const barWidth = monthScale.bandwidth();
            const barHeight = yMax - (powerScale(getConsumption(d)) ?? 0);
            const barX = monthScale(month);
            const barY = yMax - barHeight;
            return (
              <motion.rect
                {... scaleAnimationProps}
                className='mixed-chart-utility-bar-animation-class'
                key={`bar-${month}`}
                x={barX}
                y={barY}
                width={barWidth}
                height={barHeight}
                fill={'url(#consumption-gradient)'}
                rx={4}
              />
            );
          })}
        </Group>
        <AxisBottom
          top={yMax + chartMargins.top}
          left={chartMargins.left}
          scale={monthScale}
          stroke={axisColor}
          tickStroke={axisColor}
          hideAxisLine
          hideTicks
          tickLabelProps={{
            fill: axisColor,
            fontSize: 16,
            textAnchor: 'middle',
          }}
        />    
      </svg>
    </ScaleSVG>
  )
}
