import styled from 'styled-components';
import { LegendItem, LegendLabel, LegendOrdinal } from "@visx/legend";
import { scaleOrdinal } from "@visx/scale";

const LegendWrapper = styled.div`
  /* background-color: ${props => props.theme.panelBgColor}; */
  padding: 1rem 0;
  border-radius: 14px;
  width: fit-content;
  margin-bottom: 1.5rem;

  @media screen and (width < 750px) {
    margin: auto;
  }
`

const Legend = styled.div`
  display: flex;
  flex-flow: column nowrap;
`

const StyledLegendItem = styled(LegendItem)`
  padding: .5rem;
`

export default function SolarSavingsLegend() {
  const colorScale = scaleOrdinal({
    domain: ['Utility Power', 'Solar Option #1', 'Solar Option #2'],
    range: ['#F33016', '#2176AE', '#5FAB8F']
  })
  const legendGlyphSize = 15;

  return (
    <LegendWrapper>
      <LegendOrdinal scale={colorScale} >
        {(labels) => (
          <Legend>
            {labels.map((label, i) => (
              <StyledLegendItem
                key={`legend-quantile-${i}`}
              >
                <svg width={legendGlyphSize} height={legendGlyphSize}>
                  <circle
                    fill={label.value}
                      cx={legendGlyphSize / 2}
                      cy={legendGlyphSize / 2} 
                      r={legendGlyphSize / 2} 
                    />
                </svg>
                <LegendLabel
                  align="left"
                  style={{
                    fontSize: '1.75rem',
                    margin:"0 0 0 .5rem"
                  }}
                >
                  {label.text}
                </LegendLabel>
              </StyledLegendItem>
            ))}
          </Legend>
        )}
      </LegendOrdinal>
    </LegendWrapper>
  )
}
