import styled from 'styled-components';
import forestImage from '../images/HTOImages/forestHero.jpeg';
import gasPumpIcon from '../images/HTOImages/gasPump.svg';
import lightbulbIcon from '../images/HTOImages/lightbulb.svg';
import trashCanIcon from '../images/HTOImages/trashCan.svg';
import evergreenTreeIcon from '../images/HTOImages/evergreenTree.svg';
import globeIcon from '../images/HTOImages/globeNA.svg';
import { animate, motion } from "framer-motion";
import { useContext, useEffect, useRef } from 'react';
import { AnimateContext, AnimationContext } from './HTOContext/AnimationContext';
import useOnScreen from '../functions/useOnScreen';

const InfographicContainer = styled.div`
  background-image: url(${forestImage});
  color: white;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  height: 100%;
`

const Header = styled.h2`
  /* background: #181818af; */
  font-size: 1.75rem;
  font-weight: 600;
  margin: 1rem;
  border-radius: 14px;
  /* padding: 1rem; */
  width: 100%;
  line-height: 1;
`

const Subheader = styled.h3`
  /* background: #181818af; */
  padding: .5rem;
  font-size: 1.75rem;
  border-radius: 14px;
  margin: 0 .5rem .5rem .5rem;
  width: 90%;
  line-height: 1;
`

const CardContainer = styled.div`
  display: flex;
  align-items: space-evenly;
  justify-content: space-evenly;
  width: 100%;
  padding: 0 1rem;
  flex-wrap: wrap;
`

const Card = styled(motion.div)`
  /* background: #181818af; */
  padding: .75rem .25rem;
  border-radius: 14px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 7rem;
  height: 9rem;
  margin: 0 .5rem 1rem .5rem;

  img {
    height: 4.5rem;
    margin-bottom: .25rem;
  }
  h4 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 4px;
  }
  p {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
  }
`

function Counter({ from = 0, to }) {
  const animationEnabled = useContext(AnimationContext);
  const nodeRef = useRef();
  const isOnScreen = useOnScreen(nodeRef);

  useEffect(() => {
    const node = nodeRef.current;

    if (animationEnabled) {
      const controls = animate(from, to, {
        duration: 1.5,
        ease: 'easeOut',
        onUpdate(value) {
          node.textContent = value.toFixed(0).toLocaleString('en-US');
        },
      });

      return () => controls.stop();
    }

  }, [isOnScreen]);

  return <h4 ref={nodeRef} >{to}</h4>;
}

export default function HTOForestInfographic() {
  const animationEnabled = useContext(AnimationContext);

  const animationProps = (animationEnabled ?
    {
      initial: { opacity: 0, y: 10 },
      whileInView: { opacity: 1, y: 0 },
      transition: { duration: .5 },
    } :
    null
  );

  return (
    <InfographicContainer>
      <Header>Solar is green in more ways than one.</Header>
      <Subheader>Your solar system reduces your carbon footprint significantly, with an impact equivalent to:</Subheader>
      <CardContainer>
        <Card {... animationProps} >
          <img src={gasPumpIcon} alt='gas pump' />
          <Counter to={17383} />
          <p>Gallons of gasoline</p>
        </Card>
        <Card {... animationProps} >
          <img src={lightbulbIcon} alt='lightbulb' />
          <Counter to={21} />
          <p>Homes' electricity use for 1 year</p>
        </Card>
        <Card {... animationProps} >
          <img src={trashCanIcon} alt='trash can' />
          <Counter to={233} />
          <p>Tons of waste sent to landfill</p>
        </Card>
        <Card {... animationProps} >
          <img src={evergreenTreeIcon} alt='evergreen tree' />
          <Counter to={5129} />
          <p>Trees growing for 10 years</p>
        </Card>
        <Card {... animationProps} >
          <img src={globeIcon} alt='Earth' />
          <p>Reduce dependence on fossil fuels and save the planet</p>
        </Card>
      </CardContainer>
    </InfographicContainer>
  )
}
