import styled, { useTheme } from 'styled-components';
import { motion } from "framer-motion";
import { useContext } from 'react';
import { AnimationContext } from '../HTOContext/AnimationContext';

const SlideContainer = styled.section`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: ${props => props.theme.slideBottomMargin};
  text-align: center;
  margin: 8rem 0;

  h1 {
    font-size: ${props => props.theme.slideHeadingSize};
    padding: 1rem;
    margin-bottom: 1rem;
  }
`

const CardContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  width: 100%;
`

const Card = styled(motion.div)`
  flex: 1;
  height: 40rem;
  max-width: 40rem;
  padding: 1rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  img {
    border-radius: 14px;
    min-height: 100%;
    object-fit: cover;
  }
`

export default function HTOSystemDesignSlide({chartData}) {
  const theme = useTheme();

  // animation
  const animationEnabled = useContext(AnimationContext);
  const animationProps = (animationEnabled ?
    {
      initial: { opacity: .5, scale: .98 },
      whileInView: { opacity: 1, scale: 1 },
      transition: { duration: .75 },
    } :
    null
  );
  
  return (
    <SlideContainer className={theme.slideClassName}>
      <h1>YOUR SYSTEM DESIGN</h1>
      <CardContainer>
        <Card {...animationProps}>
          <h2>Current Satellite Image</h2>
          {/* <img src={chartData.currentSatelliteImage} alt='house' /> */}
          {/* TODO: replace TEMP satellite image with real image URL once available to Mulesoft */}
          <img src={chartData.currentSatelliteImageURL} alt='house' />
        </Card>
        <Card {...animationProps}>
          <h2>Custom Solar Design</h2>
          <img src={chartData.customSolarDesignImageURL} alt='house with solar panels' />
        </Card>
      </CardContainer>
    </SlideContainer>
  )
}
