import styled from "styled-components";

const Table = styled.table`
  table-layout: fixed;
  text-align: center;
  width: 100%;
  min-height: 20rem;

  caption {
    background-color: ${props => props.theme.headingBgColor};
    color: ${props => props.theme.headingTextColor};
    font-size: 1.5rem;
    font-weight: 400;
    text-transform: uppercase;
    padding: 0.5rem;
  }
  th, td {
    padding: .5rem;
  }
`

const HeaderCell = styled.th`
  background-color: ${props => props.highlight ? props.theme.headingBgColor : 'inherit'};
  color: ${props => props.highlight ? props.theme.headingTextColor : props.theme.tableMainTextColor};
  font-size: ${props => props.largeText ? '1.5rem' : 'inherit'};
  font-weight: ${props => props.highlight ? '400' : 'inherit'};
`

const DataCell = styled.td`
  background-color: ${props => props.highlight ? props.theme.headingBgColor : 'inherit'};
  color: ${props => props.highlight ? props.theme.headingTextColor : props.theme.tableMainTextColor};
  font-size: ${props => props.largeText ? '1.5rem' : 'inherit'};
  font-weight: ${props => props.highlight ? '400' : 'inherit'};
`

export default function HTOFinancingOptionDetailsTable({chartData, option}) {

  const formattedData = {
    totalSystemCost: `$${chartData.totalSystemCost.toLocaleString('en-US')}`,
    taxCredit: `$${chartData.systemTaxCredit.toLocaleString('en-US')}`,
    netSystemCost: `$${(chartData.totalSystemCost - chartData.systemTaxCredit).toLocaleString('en-US')}`,
    twentyFiveYearSavings: option === 1 ?
      `$${Math.round(chartData.twentyFiveYearSavingsOption1).toLocaleString('en-US')}` :
      `$${Math.round(chartData.twentyFiveYearSavingsOption2).toLocaleString('en-US')}`,
    termLength: option === 1 ?
      `${chartData.termLengthOption1} Years` :
      `${chartData.termLengthOption2} Years`,
    monthlyPayment: option === 1 ?
      `$${chartData.monthlyPaymentOption1.toLocaleString('en-US')}` :
      `$${chartData.monthlyPaymentOption2.toLocaleString('en-US')}`,
    twentyFiveYearSolarCost: option === 1 ?
      `$${Math.round(chartData.twentyFiveYearSolarCostOption1).toLocaleString('en-US')}` :
      `$${Math.round(chartData.twentyFiveYearSolarCostOption2).toLocaleString('en-US')}`,
    twentyFiveYearUtilityCost: `$${Math.round(chartData.twentyFiveYearUtilityCost).toLocaleString('en-US')}`,
    twentyFiveYearRemainingUtilityCost: `$${Math.round(chartData.twentyFiveYearRemainingUtilityCost).toLocaleString('en-US')}`,
  }

  return (
    <Table>
      <caption>Option #{option}</caption>
      <tbody>
        <tr>
          <DataCell largeText>{formattedData.monthlyPayment}</DataCell>
          <DataCell largeText>{formattedData.termLength}</DataCell>
          <DataCell largeText>{formattedData.twentyFiveYearSavings}</DataCell>
        </tr>
        <tr>
          <HeaderCell>SOLAR PAYMENT*</HeaderCell>
          <HeaderCell>AGREEMENT TERMS</HeaderCell>
          <HeaderCell>25 YEAR SOLAR SAVINGS</HeaderCell>
        </tr>
        <tr>
          <HeaderCell colSpan={2}>25 year cost with utility</HeaderCell>
          <DataCell>{formattedData.twentyFiveYearUtilityCost}</DataCell>
        </tr>
        <tr>
          <HeaderCell colSpan={2}>25 year solar cost</HeaderCell>
          <DataCell>{formattedData.twentyFiveYearSolarCost}</DataCell>
        </tr>
        <tr>
          <HeaderCell colSpan={2}>25 year remaining utility cost</HeaderCell>
          <DataCell>{formattedData.twentyFiveYearRemainingUtilityCost}</DataCell>
        </tr>
        <tr>
          <HeaderCell colSpan={2} highlight largeText>TOTAL 25 YEAR SAVINGS</HeaderCell>
          <DataCell highlight largeText>{formattedData.twentyFiveYearSavings}</DataCell>
        </tr>
      </tbody>
    </Table>
  )
}
