import styled from 'styled-components';
import { LegendItem, LegendLabel, LegendOrdinal } from "@visx/legend";
import { scaleOrdinal } from "@visx/scale";
import { GradientOrangeRed } from '@visx/gradient';
import { PatternLines } from '@visx/pattern';

const LegendWrapper = styled.div`
  /* background-color: ${props => props.theme.panelBgColor}; */
  /* padding: 1rem; */
  /* border-radius: 14px; */
  /* margin-top: 2rem; */
  position: absolute;
  top: 6%;
  right: 3%;
`

export default function SolarOffsetLegend() {
  const colorScale = scaleOrdinal({
    domain: ['Solar Power', 'Utility Power'],
    range: ['url(#legend-solar-gradient', 'url(#legend-utility-pattern)'],
  })
  const legendGlyphSize = 15;

  return (
    <LegendWrapper>
      <LegendOrdinal scale={colorScale} >
        {(labels) => (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {labels.map((label, i) => (
              <LegendItem
                key={`legend-quantile-${i}`}
                margin="0 10px"
              >
                <svg width={legendGlyphSize} height={legendGlyphSize}>
                  <GradientOrangeRed id="legend-solar-gradient" />
                  <PatternLines
                      id='legend-utility-pattern'
                      height={6}
                      width={6}
                      stroke={'#a2a9be'}
                      strokeWidth={1}
                      orientation={['diagonal']}
                    />
                  <circle
                    fill={label.value}
                    cx={legendGlyphSize / 2}
                    cy={legendGlyphSize / 2} 
                    r={legendGlyphSize / 2 - 1}
                    stroke={label.value === 'url(#legend-utility-pattern)' ? '#a2a9be' : label.value}
                  />
                </svg>
                <LegendLabel align="left" margin="0 0 0 6px">
                  {label.text}
                </LegendLabel>
              </LegendItem>
            ))}
          </div>
        )}
      </LegendOrdinal>
    </LegendWrapper>
  )
}
