import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AreaClosed } from '@visx/shape';
import { curveMonotoneX } from '@visx/curve';
import { GridRows, GridColumns } from '@visx/grid';
import { scaleTime, scaleLinear } from '@visx/scale';
import { LinearGradient } from '@visx/gradient';
import { max, extent } from 'd3-array';
import { PatternLines } from '@visx/pattern';
import { AxisBottom } from '@visx/axis';
import { animate, motion } from "framer-motion";
import { ScaleSVG } from '@visx/responsive';
import styled, { useTheme } from 'styled-components';
import { AnimationContext } from '../HTOContext/AnimationContext';

const StyledRect = styled(motion.rect)`
  transform-origin: bottom !important;
`

const StyledGroup = styled(motion.g)`
  transform-origin: bottom !important;
`

// accessors
const getDate = (d) => new Date(d.year);
const getUtilityCost = (d) => d.utilityCost;

export default function InvestmentIntoSolarAreaChart({chartData, width, height, chartIsOnScreen}) {
  // animation
  const animationEnabled = useContext(AnimationContext);
  const animationProps = (animationEnabled ?
    {
      initial: { scaleY: 0 },
    } :
    null
  );

  const utilityCurveRef = document.querySelector('#utility-curve-animation-id');
  const solarRectRef = document.querySelector('#solar-rect-animation-id');
  const solarRectPatternRef = document.querySelector('#solar-rect-pattern-animation-id');
  
  useEffect(() => {
    if (chartIsOnScreen && animationEnabled) {
      animate(utilityCurveRef, { scaleY: [0, 1] }, { type: "spring", mass: 0.5 });
      animate(solarRectRef, { scaleY: [0, 1] }, { type: "spring", mass: 0.5, delay: .25 });
      animate(solarRectPatternRef, { scaleY: [0, 1] }, { type: "spring", mass: 0.5, delay: .25 });
    }
  }, [chartIsOnScreen]);
  
  // data
  const data = chartData.investmentIntoSolarData;
  
  // margins
  const margin = {top: 40, right: 40, bottom: 40, left: 40};
  
  // bounds
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  // colors
  const theme = useTheme();
  const background = theme.panelBgColor;
  const axisColor = theme.pageTextColor;
  
  // scales
  const dateScale = useMemo(
    () =>
    scaleTime({
      range: [margin.left, innerWidth + margin.left],
      domain: extent(data, getDate),
    }),
    [innerWidth, margin.left],
    );
    const yScalePadding = 15;
    const costScale = useMemo(
      () =>
      scaleLinear({
        range: [innerHeight + margin.top, margin.top],
        domain: [0, (max(data, getUtilityCost) + yScalePadding || 0)],
        nice: true,
      }),
      [margin.top, innerHeight],
      );
      
      const SOLAR_COST_CONSTANT = 105;
      
      return (
        <ScaleSVG width={width} height={height}>
      <svg width={width} height={height}>
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          fill="url(#area-background-gradient)"
          rx={14}
        />
        <PatternLines
          id='area-background-diagonal-pattern'
          height={15}
          width={15}
          stroke={axisColor}
          strokeWidth={1}
          orientation={['diagonal']}
        />
        <LinearGradient id="area-background-gradient" from={background} to={background} />
        <LinearGradient id={'investment-blue-gradient'} from="#5bbdff" to="#319be28f"/>
        <LinearGradient id={'investment-red-gradient'} from="#F33016" to="#e9260c79"/>
        <GridRows
          left={margin.left}
          scale={costScale}
          width={innerWidth}
          strokeDasharray="1,3"
          stroke={axisColor}
          pointerEvents="none"
          opacity={0.5}
        />
        <GridColumns
          top={margin.top}
          scale={dateScale}
          height={innerHeight}
          strokeDasharray="1,3"
          stroke={axisColor}
          pointerEvents="none"
          opacity={0.5}
          tickValues={[1, 5, 10, 15, 20, 25]}
        />
        <StyledGroup
          {... animationProps}
          id='utility-curve-animation-id'
        >
          <AreaClosed
            data={data}
            x={(d) => dateScale(getDate(d)) ?? 0}
            y={(d) => costScale(getUtilityCost(d)) ?? 0}
            yScale={costScale}
            fill="url(#investment-red-gradient)"
            curve={curveMonotoneX}
          />
        </StyledGroup>
        <StyledRect 
          {... animationProps}
          id='solar-rect-animation-id'
          x={margin.left}
          y={height - margin.bottom - SOLAR_COST_CONSTANT}
          width={dateScale(20) - margin.left}
          height={SOLAR_COST_CONSTANT}
          fill={'url(#investment-blue-gradient)'}
          rx={7}
        />
        <StyledRect 
          {... animationProps}
          id='solar-rect-pattern-animation-id'
          transition={{ type: "spring", mass: 0.5, delay: .25 }}
          x={margin.left}
          y={height - margin.bottom - SOLAR_COST_CONSTANT}
          width={dateScale(20) - margin.left}
          height={SOLAR_COST_CONSTANT}
          fill={'url(#area-background-diagonal-pattern'}
          rx={7}
        />
        <AxisBottom
          top={height - margin.bottom}
          tickFormat={year => year + ' Years'}
          scale={dateScale}
          stroke={axisColor}
          tickStroke={axisColor}
          tickValues={[10, 20, 25]}
          hideAxisLine
          hideTicks
          tickLabelProps={{
            fill: axisColor,
            fontSize: 16,
            textAnchor: 'middle',
          }}
        />
      </svg>
    </ScaleSVG>
  );
}
