import styled from "styled-components";

const Overlay = styled.div`
  background: black;
  height: 100vh;
  opacity: .3;
  position: fixed;
  width: 100%;
  z-index: 3;
`

const Module = styled.div`
  align-items: center;
  background: rgb(194, 219, 251);
  border-radius: var(--radius1);
  display: flex;
  flex-flow: column nowrap;
  max-width: 22rem;
  position: fixed;
  top: 30%;
  text-align: center;
  width: 90%;
  z-index: 4;

  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    padding: 1rem;
  }
  p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    padding: 0 1.5rem;
  }
`

const DismissButton = styled.button`
  border-top: 1px solid gray;
  color: rgb(52, 120, 246);
  font-size: 1.25rem;
  padding: 1rem;
  width: 100%;
`

export default function ErrorPopup({heading, body, buttonCallback}) {

  const onClickHandler = (e) => {
    e.preventDefault();
    buttonCallback();
  }

  return (
    <>
      <Overlay />
      <Module>
        <h2>{heading}</h2>
        <p>{body}</p>
        <DismissButton onClick={onClickHandler}>Dismiss</DismissButton>
      </Module>
    </>
  )
}
