import { monthNames } from "./FunctionsInGeneral";

export const barsGreen = ["rgba(0, 255, 0, 1.0)", "rgba(0, 255, 0, 0.7)"]

export const labels = ["", "", "", "", ""]

export let apDefault = {
	labels,
	datasets: [
		{
			label: "",
			data: [0.25, 0.45, 0.52, 0.62, 0.75],
			borderColor: "rgb(56, 207, 42)",
			backgroundColor: barsGreen[0],
		}
	]
};

export let apBlank = {
	labels,
	datasets: [
		{
			label: "",
			data: [0, 0, 0, 0, 0],
			borderColor: "rgb(56, 207, 42)",
			backgroundColor: "lightgray"
		}
	]
};

export const apOptions = {
  responsive: true,
  maintainAspectRatio: false,
	scales: {
      x: {
        grid: {
          display: false,
        },
				ticks: {
					font: {
						size: 14
					}
				}
      },
      y: {
        grid: {
          display: true,
				},
				ticks: {
					callback: function(value, index, ticks) {
							return value + " kWh";
					}
				}
			}
		},
	animation: {
			duration: 3000,
    },
	onResize: {
	},
  plugins:
  {
    legend:
    {
      display: false,
      position: "top",
    },
    title: {
      display: false,
      text: "Your Home Energy Production (kWh)"
    },
		tooltip: {
				enabled: false
	    },
  }
};

export function prepAP(data)
{
	let fullData = data.map((value, index) => {
		return {month: index, value: value};
	}).map((element, index) => {
		return {month: element.month, value: (parseFloat(element.value))};
	})


	let labels = fullData.map(element => monthNames[parseInt(element.month) % 12]);
	let readyData = fullData.map(element => element.value);

	let formattedData = {
    labels,
    datasets: [
      {
        label: "Energy",
        data: readyData,
				borderColor: barsGreen[0],
				borderWidth: 2,
				backgroundColor: barsGreen[1]
      }
    ]
  };
  return formattedData;
}

export function prepAPYear(data)
{
	let total = 0;
	let total2 = (data.length > 12 ? 0 : -1)
	for(let i = 0; i < 12; i++)
	{
		if(data[i])
			total += parseFloat(data[i])
		if(total2 != -1)
		{
			if(data[i + 12])
				total2 += parseFloat(data[i + 12])
		}
	}
	let currentYear = new Date().getFullYear()


	let labels = [currentYear];
	let readyData = [total];
	if(total2 != -1)
	{
		labels.unshift(currentYear - 1)
		readyData.push(total2)
	}


	let formattedData = {
    labels,
    datasets: [
      {
        label: "Energy",
        data: readyData,
				borderColor: barsGreen[0],
				borderWidth: 2,
				backgroundColor: barsGreen[1]
      }
    ]
  };
  return formattedData;
}