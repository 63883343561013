import styled, { useTheme } from 'styled-components';
import HTOFinancingOptionTable from '../HTOTables/HTOFinancingOptionTable';
import HTOFinancingOptionDetailsTable from '../HTOTables/HTOFinancingOptionDetailsTable';
import ArrowIcon from '../../images/HTOImages/savingsReportUpArrow.svg';
import HouseIcon from '../../images/HTOImages/savingsReportHouseSharp.svg';
import DollarSignIcon from '../../images/HTOImages/savingsReportDollarSign.svg';
import CombinedHouseIcon from '../../images/HTOImages/savings-report-house-icon.png';
import { motion } from "framer-motion";
import { useContext, useRef } from 'react';
import { AnimationContext } from '../HTOContext/AnimationContext';

const SlideContainer = styled.section`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: ${props => props.theme.slideBottomMargin};
  text-align: center;
  margin: 8rem 0;

  h1 {
    height: 5rem;
    font-size: ${props => props.theme.slideHeadingSize};
    padding: 1rem 1rem 0 1rem;
    margin-bottom: .5rem;
    text-transform: uppercase;
  }
  h2 {
    margin-top: .5rem;
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
`

const SubsectionContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  width: 100%;
`

const TableSubsection = styled.div`
  text-align: center;
  align-items: stretch;
  display: flex;
  flex-flow: row nowrap;

  h2 {
    background-color: ${props => props.theme.headingBgColor};
    color: ${props => props.theme.headingTextColor};
    font-size: 1.5rem;
    font-weight: 400;
    text-transform: uppercase;
    width: 100%;
    margin-bottom: .5rem;
  }
`

const Card = styled.div`
  min-width: 375px;
  padding: 0 1rem 1rem 1rem;
  display: flex;
  flex: ${props => props.flexPortion};
`

const HomeValueStudySubsection = styled.div`
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  text-align: left;
  align-items: center;
  max-width: 50rem;
  justify-content: center;

  @media screen and (width < 750px) {
    flex-flow: column nowrap;
    text-align: center;
  }
`

const HouseGraphicContainer = styled.div`
  width: 15rem;
  height: 15rem;
  padding: 1.5rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;
`

const HouseGraphicImage = styled(motion.img)`
  grid-area: 1/1/2/2;
  min-width: ${props => props.size};
  max-width: ${props => props.size};
  min-height: ${props => props.size};
  max-height: ${props => props.size};
  margin-bottom: ${props => props.offset};
`

const ArrowExtenderGraphic = styled(motion.div)`
  grid-area: 1/1/2/2;
  background-color: rgb(71, 158, 246); 
  margin-bottom: -4.5rem;
  border-radius: 14px;
  width: 35%;
  height: 60%;
`

const StudyText = styled.div`
  flex: 1;
  max-width: 30rem;
  min-width: 20rem;

  h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
`

const FinePrint = styled.p`
  font-size: 0.75rem;
  margin-top: -1rem;
`

const PDFMonkeyTarget = styled.div`
  visibility: hidden;
`

export default function HTOSavingsReportSlide({chartData, option}) {
  const theme = useTheme();

  // animation
  const animationEnabled = useContext(AnimationContext);
  const arrrowAnimationProps = (animationEnabled ?
    {
      initial: { y: 50, zIndex: -2 },
      whileInView: { y: 0, zIndex: -2 },
      transition: { type: "spring" },
    } :
    null
  );
  const houseAnimationProps = (animationEnabled ?
    {
      initial: { scale: 0.9 },
      whileInView: { scale: 1 },
      transition: { duration: 0.75 },
    } :
    null
  );

  const subheaderText = (
    option === 1 ?
      `${chartData.loanCompanyNameOption1} ${chartData.termLengthOption1} Year ${chartData.loanAPROption1}` :
      `${chartData.loanCompanyNameOption2} ${chartData.termLengthOption2} Year ${chartData.loanAPROption1}`
  );

  return (
    <SlideContainer className={theme.slideClassName}>
      <h1>Estimated Savings Report</h1>
      <h2>{subheaderText}</h2>
      <SubsectionContainer>
        <TableSubsection>
          <Card flexPortion={4}>
            <HTOFinancingOptionDetailsTable chartData={chartData} option={option} />
          </Card>
          <Card flexPortion={5}>
            <HTOFinancingOptionTable chartData={chartData} option={option} />
          </Card>
        </TableSubsection>
        <HomeValueStudySubsection>
            <HouseGraphicContainer>
              <img src={CombinedHouseIcon} alt='house with dollar sign' />
              {/* <HouseGraphicImage
                {... arrrowAnimationProps}
                size="100%" 
                offset="1rem"
                src={ArrowIcon} 
                alt='up arrow' 
              />
              <ArrowExtenderGraphic
                {... arrrowAnimationProps}
                offset="1rem"
                />
              <HouseGraphicImage
                {... houseAnimationProps}
                size="65%"
                src={HouseIcon}
                alt='house with dollar sign'
              />
              <HouseGraphicImage
                {... houseAnimationProps}
                size="20%"
                offset="1.75rem"
                src={DollarSignIcon}
                alt='dollar sign'
              /> */}
            </HouseGraphicContainer>
            <StudyText>
              <h2>SOLAR INCREASES YOUR HOME'S VALUE</h2>
              <p>
                A 2015 study from the Lawrence Berkeley National Laboratory
                shows that owning a solar system can add tens of thousands of dollars in value.
              </p>
            </StudyText>
        </HomeValueStudySubsection>
      </SubsectionContainer>

      {option === 2 &&
        <>
          <FinePrint>*The figures in this proposal are estimates based on other systems in your area. The production and savings related to your system may vary.</FinePrint>
          {/* This div exists to assist PDF Monkey rendering. Please do not change the id value. */}
          <PDFMonkeyTarget id='i-like-monkeys'/>
        </>
      }
    </SlideContainer>
  )
}
