import styled from 'styled-components';

const Badge = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.25rem;
  font-weight: 700;
  justify-content: center;
  margin: auto;
  width: fit-content;
  img {
    margin-right: .5rem;
    width: 2rem;
  }
`

const CompletedUpgradeBadge = ({text, icon}) => {
  return (
    <Badge>
      <img src={icon} alt='plus sign'/>
      <span>{text}</span>
    </Badge>
  )
}

export default CompletedUpgradeBadge