import styled from 'styled-components';
import { LegendItem, LegendLabel, LegendOrdinal } from "@visx/legend";
import { scaleOrdinal } from "@visx/scale";

const LegendWrapper = styled.div`
  background-color: ${props => props.theme.panelBgColor};
  padding: 1rem;
  border-radius: 14px;
  margin-top: 2rem;
  width: fit-content;
`

export default function InvestmentIntoSolarLegend() {
  const colorScale = scaleOrdinal({
    domain: ['Investment into Solar', 'Cost of Renting Power'],
    range: ['#2b8dce', '#F33016'],
  })
  const legendGlyphSize = 15;

  return (
    <LegendWrapper>
      <LegendOrdinal scale={colorScale} >
        {(labels) => (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {labels.map((label, i) => (
              <LegendItem
                key={`legend-quantile-${i}`}
                margin="0 10px"
              >
                <svg width={legendGlyphSize} height={legendGlyphSize}>
                  <circle
                    fill={label.value}
                      cx={legendGlyphSize / 2}
                      cy={legendGlyphSize / 2} 
                      r={legendGlyphSize / 2} 
                    />
                </svg>
                <LegendLabel align="left" margin="0 0 0 6px">
                  {label.text}
                </LegendLabel>
              </LegendItem>
            ))}
          </div>
        )}
      </LegendOrdinal>
    </LegendWrapper>
  )
}
