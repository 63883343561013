import React, { useState } from "react";
import { useEffect } from "react";
import './StageSelector.css';

const StageNode = (props) => {

    const [classNames, setClassNames] = useState([]);

  useEffect(() => {
    let tempClassNames = ['stage-node'];
    if (!props.complete) tempClassNames.push('stage-node-incomplete');
    if (props.current) tempClassNames.push('stage-node-current');
    if (props.active && props.complete) tempClassNames.push('stage-node-complete-active');
    if (props.active && props.current) tempClassNames.push('stage-node-current-active');
    if (props.active && !props.complete) tempClassNames.push('stage-node-incomplete-active');
    setClassNames(tempClassNames.join(' '));
  }, [props.current, props.active, props.complete]);

  

  return (
    <button className={classNames} onClick={props.onClick}>
        {props.stageNumber}
    </button>
  )
}

const StageSelector = (props) => {
  return (
    <div className="stage-selector-container">
      <ol className="stage-selector">
        {(props.MOCK_STAGES != undefined)
        ?
        props.MOCK_STAGES.map((stage, index) => {
          return <li key={stage.name}>
            <StageNode
              stageNumber={index + 1}
              complete={stage.complete}
              current={stage.current}
              onClick={() => props.setActiveStage(index + 1)}
              active={(props.activeStage === index + 1)}
            />
          </li>
        })
        :
        null
        }
      </ol>
    </div>
  )
}

export default StageSelector