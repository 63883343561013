import { AxisBottom, AxisLeft } from '@visx/axis';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { BarGroup } from '@visx/shape';
import { LinearGradient } from '@visx/gradient';
import { animate, motion, useAnimate } from "framer-motion";
import { ScaleSVG } from '@visx/responsive';
import styled, { useTheme } from 'styled-components';
import { useContext, useEffect, useState } from 'react';
import { AnimationContext } from '../HTOContext/AnimationContext';

const StyledRect = styled(motion.rect)`
  transform-origin: bottom !important;
`

const StyledGroup = styled(motion.g)`
  transform-origin: bottom !important;
`

const EnergyCostsBarChart = ({chartData, width, height, chartIsOnScreen}) => {
  const data = chartData.energyCostsData;
  const keys = Object.keys(data[0]).filter((d) => d !== 'year');
  
  // animations
  const animationEnabled = useContext(AnimationContext);

  const rects = document.querySelectorAll('.energy-costs-chart-rect');
  const labels = document.querySelectorAll('.energy-costs-chart-label');
  
  useEffect(() => {
    if (chartIsOnScreen && animationEnabled) {
      rects.forEach((rect, i) => {
        animate(rect, { scaleY: [0, 1] }, { type: "spring", mass: 0.5, delay: i / 20 });
      });
      labels.forEach((label, i) => {
        animate(label, { scaleY: [0, 1] }, { type: "spring", mass: 0.5, delay: i / 20 });
      });
    }
  }, [chartIsOnScreen]);
  
  // accessors
  const getYear = d => d.year;
  
  // colors
  const theme = useTheme();
  const background = theme.panelBgColor;
  const axisColor = theme.pageTextColor;

  // scales
  const yearScale = scaleBand({
    domain: data.map(getYear),
    padding: 0.2,
  });
  const billIntervalScale = scaleBand({
    domain: keys,
    padding: 0.1,
  });
  const yScaleDomainPadding = 500;
  const costScale = scaleLinear({
    domain: [0, Math.max(...data.map((d) => Math.max(...keys.map((key) => Number(d[key]))))) + yScaleDomainPadding],
  });
  const colorScale = scaleOrdinal({
    domain: keys,
    range: ['url(#blue-gradient)', 'url(#red-gradient)'],
  });

  const chartWidth = width || 750;
  const chartHeight = height || 500;
  const chartMargins = { top: 20, right: 20, bottom: 40, left: 40 };

  const xMax = chartWidth - chartMargins.left - chartMargins.right;
  const yMax = chartHeight - chartMargins.top - chartMargins.bottom;

  const leftAxisOffset = 25;

  const textLabelPadding = 8;

  // update scale output dimensions
  yearScale.rangeRound([0, xMax]);
  billIntervalScale.rangeRound([0, yearScale.bandwidth()]);
  costScale.range([yMax, 0]);

  return chartWidth < 10 ? null : (
    <ScaleSVG width={chartWidth} height={chartHeight}>
      <svg width={chartWidth} height={chartHeight}>
        <rect x={0} y={0} width={chartWidth} height={chartHeight} fill={background} rx={14} />
        <LinearGradient id={'blue-gradient'} from="#2176AE" to="#2176ae8f"/>
        <LinearGradient id={'red-gradient'} from="#F33016" to="#e9260c79"/>
        <GridRows
          top={chartMargins.top}
          left={chartMargins.left + leftAxisOffset}
          scale={costScale}
          width={xMax - chartMargins.left}
          height={yMax}
          stroke={axisColor}
          opacity={.5}
          strokeDasharray="1,3"
        />
        <Group top={chartMargins.top} left={chartMargins.left}>
          <BarGroup
            data={data}
            keys={keys}
            height={yMax}
            x0={getYear}
            x0Scale={yearScale}
            x1Scale={billIntervalScale}
            yScale={costScale}
            color={colorScale}
          >
            {(barGroups) =>
              barGroups.map((barGroup, i) => (
                <Group key={`bar-group-${barGroup.index}-${barGroup.x0}`} left={barGroup.x0}>
                  {barGroup.bars.map((bar) => (
                    <g key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}>
                      <StyledRect
                        className='energy-costs-chart-rect'
                        x={bar.x}
                        y={bar.y}
                        width={bar.width}
                        height={
                          animationEnabled ?
                            (
                              chartIsOnScreen ?
                                bar.height :
                                0
                            ) :
                            bar.height
                        }
                        fill={bar.color}
                        rx={4}
                      />
                      <StyledGroup className='energy-costs-chart-label'>
                        <text
                          textAnchor='middle'
                          fontSize={'1rem'}
                          x={bar.x + billIntervalScale.bandwidth() / 2}
                          y={bar.y - textLabelPadding}
                        >
                          ${(bar.value).toLocaleString()}
                        </text>
                      </StyledGroup>
                    </g>
                  ))}
                </Group>
              ))
            }
          </BarGroup>
        </Group>
        <AxisBottom
          top={yMax + chartMargins.top}
          left={chartMargins.left}
          tickFormat={year => 'Year ' + year}
          scale={yearScale}
          stroke={axisColor}
          tickStroke={axisColor}
          hideAxisLine
          hideTicks
          tickLabelProps={{
            fill: axisColor,
            fontSize: 16,
            textAnchor: 'middle',
            scaleToFit: false
          }}
        />
        <AxisLeft 
          scale={costScale} 
          rangePadding={{start: 0, end: 5000}}
          top={chartMargins.top}
          left={chartMargins.left + leftAxisOffset}
          tickFormat={cost => `$${cost}`}
          hideAxisLine
          hideTicks
          numTicks={4}
          tickLabelProps={() => ({
            fill: axisColor,
            fontSize: 16,
            textAnchor: 'end',
            verticalAnchor: 'middle',
            scaleToFit: false
          })}
          stroke={axisColor}
        />      
      </svg>
    </ScaleSVG>
  )
}

export default EnergyCostsBarChart
