import React from 'react';
import { useState, useEffect } from 'react';
import styled from "styled-components";
import './Milestone.css';
import UploadButton from './UploadButton';
import GreenCheck from './images/icons/CheckGreen.svg';
import RedCheck from './images/icons/CheckRed.svg';
import GrayCheck from './images/icons/CheckGray.svg';

const SurveyButton = styled.button`
    color: ${props => props.submissionStatus
        ?
        'var(--SharedRemainderRed)'
        :
        'inherit'
    };

    background-color: ${props => props.grayedOut ? "var(--gray2) !important" : "inherit"};
`

function Milestone(props) {

    const [submissionStatus, setSubmissionStatus] = useState(composeSubmissionStatus());

    function composeSubmissionStatus()
    {
        return(
            props.submissionCheck === 'installDateSurvey' && (props.rejectedInstallSurvey || props.rejectedReturnInstallSurvey) ? 'rejected' :
            ''
        )
    }

    useEffect(() => {
        setSubmissionStatus(composeSubmissionStatus())
    }, [props.submissionCheck, props.completedHOA, props.completedInstallSurvey, props.rejectedInstallSurvey, props.completedReturnInstallSurvey, props.rejectedReturnInstallSurvey]);

    function composeButtonText()
    {
        return(
            props.submissionCheck === 'HOASurvey' ? 'Take HOA Survey' :
            props.submissionCheck === 'installDateSurvey' && props.rejectedInstallSurvey ? "Survey Submitted: We'll propose a new date soon" :
            props.submissionCheck === 'installDateSurvey' && props.sfData.installationScheduleDate == null ? 'Install Date Survey' :
            props.submissionCheck === 'installDateSurvey' && props.sfData.installReturnDate == null && props.installSurveyReady ? 'Confirm Install Date' :
            props.submissionCheck === 'installDateSurvey' && !props.rejectedReturnInstallSurvey ? 'Confirm Return Install Date' :
            props.submissionCheck === 'installDateSurvey' && props.rejectedReturnInstallSurvey ? "Return Survey Submitted: We'll propose a new date soon" :
            props.name
        )
    }

    const [buttonText, setButtonText] = useState(
        composeButtonText()
    );

    useEffect(() => {
        setButtonText(composeButtonText())
    }, [props.submissionCheck, props.completedInstallSurvey, props.rejectedInstallSurvey, props.completedReturnInstallSurvey, props.rejectedReturnInstallSurvey, props.name])
    
    return(
        <div className="milestone-item-container">
            {
                /*
                (props.submissionCheck === "HOASurvey" && props.completedHOA && !props.complete) ||
                (props.submissionCheck === "installDateSurvey" && props.completedInstallSurvey && !props.complete) ||
                (props.submissionCheck === "insuranceUpload" && props.completedInsurance && !props.complete) ?
                <img src={GreenCheck} className="milestone-check-icon" alt="green check mark"/> : null
                */
            }
            {
                (props.submissionCheck === "installDateSurvey" && (props.rejectedInstallSurvey || props.rejectedReturnInstallSurvey) && !props.complete) ?
                <img src={RedCheck} className="milestone-check-icon" alt="red check mark"/> : null
            }
            {
                !props.complete
                ?
                    props.type === 'wifi-survey' && !props.completedWiFi ?
                        <SurveyButton
                            className='survey'
                            onClick={props.clickAction}
                        >
                            <p>{buttonText}</p>
                        </SurveyButton>
                    :
                    props.type == "survey"
                    ?
                    <SurveyButton
                        className=
                        {
                            (props.submissionCheck === "installDateSurvey" && (props.rejectedInstallSurvey || props.rejectedReturnInstallSurvey) && !props.complete)
                            ?
                                ''
                            :
                                'survey'
                        }
                        submissionStatus={submissionStatus}
                        onClick={props.clickAction}
                        grayedOut={props.submissionCheck === 'installDateSurvey' && props.sfData.installationScheduleDate == null}
                    >
                        <p>{buttonText}</p>
                    </SurveyButton>
                    :
                        props.type == "file upload"
                        ?
                        <UploadButton text={props.name} sfData={props.sfData} accountIdv3={props.accountIdv3} completedInsurance={props.completedInsurance} setCompletedInsurance={props.setCompletedInsurance} updateSFdata={props.updateSFdata} />
                        :
                            props.type == "file upload with popup"
                            ?
                            <p className="looksLikeUploadButton" onClick={props.clickAction}>{props.name}</p>
                            :
                            <p>{props.name}</p>
                :
                props.submissionCheck == 'installDateSurvey'
                ?
                <div className="completedMilestone"><p className="specialMilestone" id={props.stageColor}>{props.name}</p></div>
                :
                <div className="completedMilestone"><img src={GrayCheck} className="" alt="gray check mark"/><p>{props.name}</p></div>
            }
        </div>
    );
}

export default Milestone;