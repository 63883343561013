import React, { useContext, useEffect, useState } from 'react';
import Pie from '@visx/shape/lib/shapes/Pie';
import { scaleOrdinal } from '@visx/scale';
import { Group } from '@visx/group';
import { GradientOrangeRed, LinearGradient } from '@visx/gradient';
import { PatternLines } from '@visx/pattern'; 
import { animated, useTransition, interpolate, useInView } from '@react-spring/web';
import { motion } from "framer-motion";
import { ScaleSVG } from '@visx/responsive';
import { useTheme } from 'styled-components';
import { AnimationContext } from '../HTOContext/AnimationContext';

const defaultMargin = { top: 40, right: 40, bottom: 40, left: 40 };

const utilityColor = '#d8d2d2';

export default function SolarOffsetDonutChart({
  chartData,
  width,
  height,
  margin = defaultMargin,
  animationEnabled = true,
  chartIsOnScreen
}) {
  // animation
  animationEnabled = useContext(AnimationContext);
  const textAnimationProps = (animationEnabled ?
    {
      transition: { duration: 0.5 }
    } :
    null
  );
  
  const data = [
    {
      label: 'Solar Power',
      power: chartData.solarPowerPercent
    },
    {
      label: 'Utility Power',
      power: chartData.utilityPowerPercent
    },
  ];
  const labels = ['Solar Power', 'Utility Power'];
  
  // accessor functions
  const power = (d) => d.power;
  
  // color scales
  const theme = useTheme();
  const background = theme.panelBgColor;

  const getPowerColor = scaleOrdinal({
    domain: labels,
    range: [
      'url(#solar-gradient)',
      utilityColor,
    ],
  });
  
  if (width < 10) return null;
  
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const donutThickness = 125;
  
  return (
    <ScaleSVG width={width} height={height}>
      <svg width={width} height={height}>
        {/* <GradientOrangeRed id="solar-gradient" /> */}
        <LinearGradient id="solar-gradient" from='#98dd6f' to='#5c9939'/>
        <LinearGradient id="text-gradient" from='#78c74a' to='#549c2b'/>
        <PatternLines
            id='utility-pattern'
            height={8}
            width={8}
            stroke={utilityColor}
            strokeWidth={1}
            orientation={['diagonal']}
          />
        <rect rx={14} width={width} height={height} fill={background} />
        <Group top={centerY + margin.top} left={centerX + margin.left}>
          <Pie
            data={
              animationEnabled ?
                (
                  chartIsOnScreen ?
                    data :
                    [] 
                ) :
                data
            }
            pieValue={power}
            outerRadius={radius}
            innerRadius={radius - donutThickness}
            cornerRadius={3}
            padAngle={0.015}
          >
            {(pie) => (
              <AnimatedPie
                {...pie}
                animationEnabled={animationEnabled}
                getKey={(arc) => arc.data.label}
                getColor={(arc) => getPowerColor(arc.data.label)}
              />
            )}
          </Pie>
          <motion.text
            {... textAnimationProps}
            textAnchor='middle'
            verticalAnchor='middle'
            fontWeight={600}
            fill={'url(#text-gradient)'}
            dy={20}
            fontSize={'3.5rem'}
          >
            {data[0].power}%
          </motion.text>
        </Group>
      </svg>
    </ScaleSVG>
  );
}

const fromLeaveTransition = ({ endAngle }) => ({
  // enter from 360° if end angle is > 180°
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0,
});
const enterUpdateTransition = ({ startAngle, endAngle }) => ({
  startAngle,
  endAngle,
  opacity: 1,
});

function AnimatedPie({
  animationEnabled,
  arcs,
  path,
  getKey,
  getColor,
}) {
  const transitions = useTransition(arcs, {
    from: animationEnabled ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animationEnabled ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey,
    config: {
      tension: 80,
      friction: 18
    }
  });
  return transitions((props, arc, { key }) => {

    return (
      <g key={key}>
        <animated.path
          // compute interpolated path d attribute from intermediate angle values
          d={interpolate([props.startAngle, props.endAngle], (startAngle, endAngle) =>
            path({
              ...arc,
              startAngle,
              endAngle,
            }),
          )}
          stroke={getColor(arc)}
          fill={getColor(arc)}
        />
      </g>
    );
  });
}
