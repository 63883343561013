import React from 'react';
import '../App.css';

import { formatMoney } from '../functions/FunctionsInGeneral';

import BarChart from '../BarChart';
import TimeBar from '../TimeBar';
import InstallDateSurvey from '../InstallDateSurvey';
import HOASurveyForm from '../HOASurveyForm';
import AlertBanner from '../AlertBanner';


import { dateToEpochSec, epochNowSec, labels, empOptions, empOptionsDefault } from '../functions/FunctionsEnergyUsage'

const EnergyUsagePage = (props) => {
    return (
        <>
            <div className='chart-page-container'>
                {props.showInstallDateSurvey ? <InstallDateSurvey installData={props.sfData} setShowInstallDateSurvey={props.setShowInstallDateSurvey} accountIdv3={props.accountId} updateSFdata={props.updateSFdata} /> : null}

                {/* {props.showHOASurvey ? <HOASurveyForm setShowHOASurvey={props.setShowHOASurvey} accountId={props.accountId} accountIdv3={props.accountId} updateSFdata={props.updateSFdata} sfData={props.sfData} /> : null} */}

                {props.alertData.text ? <AlertBanner alertData={props.alertData} setShowHOASurvey={props.setShowHOASurvey} setShowInstallDateSurvey={props.setShowInstallDateSurvey} updateSFdata={props.updateSFdata} /> : null}

                <h1 className="page-title">Your Home Energy Use (kWh)</h1>

                {
                    (props.empData.length != 0 && !props.showOverlay)
                        ?
                        props.empData == props.empDefault
                            ?
                            <BarChart data={props.empData} options={empOptionsDefault} />
                            :
                            <BarChart data={props.empData} options={empOptions} />
                        :
                        null
                }

                <TimeBar timeWindow={props.timeWindow} setTimeWindow={props.setTimeWindow} />
            </div>
            {
                props.empDataReady && !props.showOverlay
                    ?
                    null
                    :
                    <>
                        <div className="box-center bg-gray-200 pageOverlay" style={props.empDataReady ? { opacity: "1" } : { opacity: "0.9" }}>
                        </div>
                        <div className="box-center pageOverlay">
                            <div className="pageOverlayTextContainer">
                                <h4 className="opacity-100 pageOverlayText empNoDataText">
                                    {
                                        (props.sfData.monitoringDeviceId == null || props.sfData.monitoringDeviceId == "") && (props.sfData.siteAnalysisCompletionDate == null)
                                            ||
                                            (props.sfData.monitoringDeviceId != null && props.sfData.monitoringDeviceId != "") && (props.sfData.siteAnalysisCompletionDate != null) && !props.empDataReady
                                            &&
                                            (Math.abs(epochNowSec() - dateToEpochSec(new Date(props.sfData.siteAnalysisCompletionDate))) <= 86400)
                                            ?
                                            <>
                                                <div className="textBlue">
                                                    Your energy usage will show here soon after your Site Analysis!
                                                </div>
                                                <>
                                                    {
                                                        props.sfData.twentyFiveYearSavings
                                                            ?
                                                            <><br />Based on your past bills, your 25-year est. savings with Everlight: <br /> <strong className="greenText">{formatMoney(props.sfData.twentyFiveYearSavings)}</strong></>
                                                            :
                                                            null
                                                    }
                                                </>
                                            </>
                                            :
                                            (props.sfData.monitoringDeviceId != null && props.sfData.monitoringDeviceId != "") && (props.sfData.siteAnalysisCompletionDate != null) && !props.empDataReady
                                                &&
                                                ((Math.abs(epochNowSec() - dateToEpochSec(new Date(props.sfData.siteAnalysisCompletionDate))) > 86400))
                                                ||
                                                (props.sfData.monitoringDeviceId == null || props.sfData.monitoringDeviceId == "") && (props.sfData.siteAnalysisCompletionDate != null) // No device ID, but SA filled out? This is USER ERROR.
                                                ?
                                                <>
                                                    <div className="textBlue">
                                                        With an upgrade, your home energy use will show here!
                                                    </div>
                                                    <br />
                                                    Please reach out <a href="mailto:Everlight%20Solar%20Customer%20Success<everlightcustomersuccess@1vjuyt27ayf4327yq60ji5xw5bwgqoq4rc5mrm64p9qchz7urc.1u-11vnmuaq.na85.case.salesforce.com>?subject=Everlight%20Solar%20Usage%20Monitor%20-%20Request%20for%20Device&body=Hello%20Everlight%20team,%20I%20am%20reaching%20out%20to%20inquire%20about%20how%20I%20can%20get%20set%20up%20to%20start%20seeing%20my%20energy%20usage.%20My%20name%20and%20address%20is%20:%20(PLEASE%20ADD%20HERE)" className="blueLink">here</a> to choose the best upgrade for your home.
                                                </>
                                                :
                                                <>
                                                    <div className="textBlue">
                                                        Your energy usage will show here soon after your Site Analysis!
                                                    </div>
                                                    <>
                                                        {
                                                            props.sfData.twentyFiveYearSavings
                                                                ?
                                                                <><br />Based on your past bills, your 25-year est. savings with Everlight: <br /> <strong className="greenText">{formatMoney(props.sfData.twentyFiveYearSavings)}</strong></>
                                                                :
                                                                null
                                                        }
                                                    </>
                                                </>

                                    }

                                </h4>
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default EnergyUsagePage