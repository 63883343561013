const mockReferralDataPartial = [
  {
    date: new Date('4-1-23'),
    expected: 27,
    actual: 82
  },
  {
    date: new Date('5-1-23'),
    expected: 36,
    actual: 79
  },
  {
    date: new Date('6-1-23'),
    expected: 63,
    actual: 143
  },
  {
    date: new Date('7-1-23'),
    expected: 143,
    actual: 191
  },
]

export default mockReferralDataPartial;
