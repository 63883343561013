import React from 'react';
import { useState, useEffect } from 'react';
import styled from "styled-components";
import './Popup.css';

function Popup(props)
{
    return(
        <div className={props.parentDivClass}>
            <div className={props.secondDivClass}>
                <p className="bold2">
                    { props.text }
                </p>
                {
                    props.noButton
                    ?
                    null
                    :
                    <button className="close-popup" onClick={props.visible}>
                        { props.buttonText }
                    </button>
                }
            </div>
        </div>
    );
}

export default Popup;