import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { cssVar } from './functions/FunctionsInGeneral.js';
import './TimeBar.css'
import { useWindowWidth } from './functions/useWindowWidth.js';

const TimeBar = (props) => {
	const windowWidth = useWindowWidth()
	const d = useRef();
	const w = useRef();
	const m = useRef();
	const vl1 = useRef();
	const vl2 = useRef();

	useEffect(() => {
		if (props.timeWindow == "day") {
			
			d.current.style.background = "white";
			w.current.style.background = "";
			m.current.style.background = "";
			vl1.current.style.background = "";
			vl2.current.style.background = cssVar("--timeBarMediumGray");
			
		}
		else if (props.timeWindow == "week") {
			
			d.current.style.background = "";
			w.current.style.background = 'white';
			m.current.style.background = "";
			vl1.current.style.background = "";
			vl2.current.style.background = "";
			
		}
		else {
			
			d.current.style.background = "";
			w.current.style.background = "";
			m.current.style.background = 'white';
			vl1.current.style.background = cssVar("--timeBarMediumGray");
			vl2.current.style.background = "";
			
		}
	}, [props.timeWindow]);

	return (
		<div className="TimeBar center">

			<button ref={d} className="TimeBarItem" onClick={() => { props.setTimeWindow("day") }}>
				Day
			</button>

			<div ref={vl1} className="TimeBarVerticalLine"></div>

			<button ref={w} className="TimeBarItem" onClick={() => { props.setTimeWindow("week") }}>
				Week
			</button>

			<div ref={vl2} className="TimeBarVerticalLine"></div>

			<button ref={m} className="TimeBarItem" onClick={() => { props.setTimeWindow("month") }}>
				Month
			</button>
			{ /*
        <div className="TimeBarItem box-center hover1">
          <div className="box-center"><p>{props.timeWindow}</p></div>
        </div>
      */ }

		</div>
	);
}

export default TimeBar;