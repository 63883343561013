import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { useParams } from "react-router-dom"
import ReferralPDFChart from "../ReferralPDFComponents/ReferralPDFChart";
import mockReferralDataPartial from "../ReferralPDFComponents/mockReferralDataPartial";
import mockReferralDataFull from "../ReferralPDFComponents/mockReferralDataFull";
import ReferralHeader from "../ReferralPDFComponents/Header";
import PersonalInfo from "../ReferralPDFComponents/PersonalInfo";
import ProductionData from "../ReferralPDFComponents/ProductionData";

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 50px 100px;
`

const ChartContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
  margin-top: 20px;
  width: 99%;
  padding: 0 25px;
`

const parseReferralData = (referralData) => {
  const firstProductionDate = new Date(referralData.actualProduction.Date_of_First_Confirmed_Solar_Production__c);
  const actualStartMonth = firstProductionDate.getMonth();

  const actualDataKeys = [
    'First_Month_Actual_Solar_Prod_KWH__c',
    'Second_Month_Actual_Solar_Prod_KWH__c',
    'Third_Month_Actual_Solar_Prod_KWH__c',
    'Fourth_Month_Actual_Solar_Prod_KWH__c',
    'Fifth_Month_Actual_Solar_Prod_KWH__c',
    'Sixth_Month_Actual_Solar_Prod_KWH__c',
    'Seventh_Month_Actual_Solar_Prod_KWH__c',
    'Eighth_Month_Actual_Solar_Prod_KWH__c',
    'Ninth_Month_Actual_Solar_Prod_KWH__c',
    'Tenth_Month_Actual_Solar_Prod_KWH__c',
    'Eleventh_Month_Actual_Solar_Prod_KWH__c',
    'Twelfth_Month_Actual_Solar_Prod_KWH__c'
  ];

  const estimatedDataKeys = [
    'First_Month_Est_Solar_Prod_KWH__c',
    'Second_Month_Est_Solar_Prod_KWH__c',
    'Third_Month_Est_Solar_Prod_KWH__c',
    'Fourth_Month_Est_Solar_Prod_KWH__c',
    'Fifth_Month_Est_Solar_Prod_KWH__c',
    'Sixth_Month_Est_Solar_Prod_KWH__c',
    'Seventh_Month_Est_Solar_Prod_KWH__c',
    'Eighth_Month_Est_Solar_Prod_KWH__c',
    'Ninth_Month_Est_Solar_Prod_KWH__c',
    'Tenth_Month_Est_Solar_Prod_KWH__c',
    'Eleventh_Month_Est_Solar_Prod_KWH__c',
    'Twelfth_Month_Est_Solar_Prod_KWH__c'
  ];

  const reorderedEstimatedDataKeys = [];

  // Reorder estimated data keys based on the actual start month
  for (let i = 0; i < 12; i++) {
    const index = (i + actualStartMonth) % 12;
    reorderedEstimatedDataKeys.push(estimatedDataKeys[index]);
  }

  const chartDataArray = [];

  let currentYear = firstProductionDate.getFullYear();

  for (let i = 0; i < 12; i++) {
    const date = new Date(firstProductionDate);
    date.setMonth((firstProductionDate.getMonth() + i) % 12);
    
    console.log(date.getMonth());
    if (date.getMonth() === 0) {
      currentYear++;
    }
    
    date.setYear(currentYear);
    date.setDate(1);
    date.setHours(0, 0, 0, 0);

    chartDataArray.push({
      date: date,
      actual: referralData.actualProduction[actualDataKeys[i]] || 0,
      expected: referralData.estimatedProduction[reorderedEstimatedDataKeys[i]] || 0
    });
  }

  console.log(chartDataArray);
  return chartDataArray;
};

const ReferralPDFPage = ({showOverlay}) => {
  const { OpportunityID } = useParams();
  const [referralData, setReferralData] = useState(null);
  const [chartData, setChartData] = useState(null);

  const referralDataEndpoint = 'https://damp-ridge-21682.herokuapp.com/referralData';

  useEffect(() => {
    console.log('opportunityId', OpportunityID);

    fetch(referralDataEndpoint, {
      headers: {
        'Content-Type': 'text/plain',
        'OpportunityID': OpportunityID
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setReferralData(data);
        setChartData(parseReferralData(data));
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    OpportunityID && referralData && chartData && !showOverlay &&
      <PageContainer>
        <ReferralHeader referralData={referralData} />
        <PersonalInfo referralData={referralData} />
        <ChartContainer>
          <ReferralPDFChart chartData={chartData} height={300} width={400} />
        </ChartContainer>
        <ProductionData referralData={referralData} />
      </PageContainer>
  );
}

export default ReferralPDFPage;