// Computing which of the 4 phases the opportunity is in

function determineStageTitle(oppStageFormula, ecuId, siteAnalysisDate, stepCurrent, stepSelected)
{
    if(oppStageFormula == "Contract Signed" && stepCurrent == stepSelected)
    {
        if(siteAnalysisDate == null && ecuId == null)
            return "Property Inspection Scheduling"
        if(siteAnalysisDate != null && ecuId == null)
            return "Awaiting Customer Documents"
        if(siteAnalysisDate == null && ecuId != null)
            return oppStageFormula; // "NOT handled in Miro example"
        return "Install Part 1 Complete"
    }
    else
    {
        if(stepCurrent == stepSelected) // Current phase
            return oppStageFormula; // TO-DO Determine access for sfData.customerFacingStageName
        else if(stepCurrent > stepSelected) // Completed phase
        {
            if(stepSelected == 0)
                return "Documentation Review"
            if(stepSelected == 1)
                return "Permits Submitted"
            if(stepSelected == 2)
                return "Installation Scheduled"
            return "Project Complete"
        }
        else // Future phase
        {
            if(stepSelected == 0)
                return "Property Inspection Scheduling"
            if(stepSelected == 1)
                return "Permits Submitted"
            if(stepSelected == 2)
                return "Permits Approved"
            return "Installation Complete"
        }
    }
    return
}

function computeCurrentPhase(stage)
{
    switch(stage)
    {
        case "Contract Signed": return 0;
        case "Perfect Packet": return 0;
        case "Awaiting Customer Info": return 0;
        case "CAD Created": return 0;

        case "Permits Approved": return 2;
        case "Permits Submitted": return 1;

        case "Installation Scheduled": return 2;

        case "Installation Complete": return 3;
        // case "System is Live!": return 3;
    }
    return 0;
}

export { determineStageTitle, computeCurrentPhase }