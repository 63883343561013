import React from 'react';
import styled from 'styled-components';
import UploadButton from './UploadButton';

const Banner = styled.button`
  background: repeating-linear-gradient(45deg, #d62d2c, #d62d2c 20px, #d22c2b 20px, #d22c2b 40px) ;
  color: white;
  padding: .5rem;
  width: 100%;

  .uploadButton label {
    cursor: pointer;
  }
`

const AlertBanner = (props) => {

  const onBannerClick = (e) => {
    e.preventDefault();
    switch (props.alertData.behavior) {
      case 'HOASurvey' : {
        props.setShowHOASurvey(true);
        break;
      }
      case 'installDateSurvey' : {
        props.setShowInstallDateSurvey(true);
        break;
      }
      default : {
        break;
      }
    }
  }

  return (
    <>
      {(props.alertData.behavior === 'HOASurvey' || props.alertData.behavior === 'installDateSurvey') ?
        <Banner onClick={onBannerClick}>
          {props.alertData.text}
        </Banner> :
        <Banner>
          <UploadButton text={props.alertData.text} updateSFdata={props.updateSFdata}/>
        </Banner>
      }
    </>
  )
}

export default AlertBanner
